// helper functions for determining traffic light output zone and hf edu review flag

const example_list = ["no","chest_pain+severe","no","one","no"];
// const example_string = "yes:severe,leg_swelling+chest_pain:severe+fever+mucus,no,yes,no";

const zone_list = ["green", "yellow_consider", "yellow_call", "red"];

function setDefaultOutputVars(){
	var zone_result=zone_list[0];
	var review_flag=false;
	return { zone_result, review_flag };
}

function checkStringForMultiple(threshold, string_to_search, valid_string_list){
	var match_count = 0;
	for (var i = 0; i < valid_string_list.length; i++){
		if (string_to_search.includes(valid_string_list[i])){
			match_count++;
		}
		if (match_count>=threshold){
			return true;
		}
	}
	return false;
}

// shortness of breath
export function getResultSob(answer_list){
	var {zone_result, review_flag} = setDefaultOutputVars();
	
	// red - exit early
	if (answer_list[1].includes("chest_pain:severe")){
		return {
			'zone': zone_list[3], 
			'reviewFlag': true
		};
	}
	// yellow_consider (no hf review)
	if (answer_list[1].includes("fever") ||
	checkStringForMultiple(2,answer_list[1],["cough","leg_swelling","mucus","wheeze"])) {
		zone_result = zone_list[1];
	}
	// yellow_consider and hf review
	if (answer_list[0]==="yes" || 
	answer_list[1].includes("chest_pain:not_severe") ||
	answer_list[2]==="yes"){
		zone_result = zone_list[1];
		review_flag = true;
	}
	// other hf review scenarios
	if (answer_list[3]==="yes" ||
	checkStringForMultiple(1,answer_list[1],["cough","leg_swelling"])){
		review_flag = true;
	}

	return {
		'zone': zone_result, 
		'reviewFlag': review_flag
	};
}

// chest discomfort
export function getResultChestDiscomfort(answer_list){
	var {zone_result, review_flag} = setDefaultOutputVars();
	
	// red - exit early
	if (answer_list[1].includes("shooting_pain")){
		return {
			'zone': zone_list[3], 
			'reviewFlag': true
		};
	}
	// yellow_consider (no hf review)
	if (answer_list[0]==="yes" ||
	answer_list[2]==="rest" ||
	checkStringForMultiple(1,answer_list[1],["sob","heart_racing"] ||
	checkStringForMultiple(2,answer_list[1],["sob","heart_racing","sweating","nausea","regurg"]))){
		zone_result = zone_list[1];
	}

	return {
		'zone': zone_result, 
		'reviewFlag': review_flag
	};
}

// fatigue
export function getResultFatigue(answer_list){
	var {zone_result, review_flag} = setDefaultOutputVars();
	
	// yellow_consider
	if (answer_list[0].includes("fever") ||
	checkStringForMultiple(2,answer_list[0],["sob","weight_change","leg_swelling","cough","lightheaded"]) ||
	answer_list[1]==="yes"){
		zone_result = zone_list[1];
	}
	// hf review scenarios
	if (checkStringForMultiple(1,answer_list[0],["weight_change","leg_swelling","cough","lightheaded"]) || 
	answer_list[2]==="no") {
		review_flag = true;
	}

	return {
		'zone': zone_result, 
		'reviewFlag': review_flag
	};
}

// weight change
export function getResultWeightChange(answer_list){
	var {zone_result, review_flag} = setDefaultOutputVars();
	
	// yellow_consider (no hf review)
	if (answer_list[1]==="no"){
		zone_result = zone_list[1];
	}
	// yellow_consider and hf review
	if (checkStringForMultiple(1,answer_list[2],["sob","cough","leg_swelling"]) && 
	(answer_list[1]==="no" || answer_list[0]==="other")){
		zone_result = zone_list[1];
		review_flag = true;
	}
	// yellow_call (no hf review)
	if (answer_list[0]==="day_gain" || answer_list[0]==="week_gain"){
		zone_result = zone_list[2];
	}
	// yellow_call and hf review
	if (checkStringForMultiple(2,answer_list[2],["sob","cough","leg_swelling"]) && 
	(answer_list[1]==="no" || answer_list[0]==="other")){
		zone_result = zone_list[2];
		review_flag = true;
	}
	
	return {
		'zone': zone_result, 
		'reviewFlag': review_flag
	};
}

// confusion
export function getResultConfusion(answer_list){
	var {zone_result, review_flag} = setDefaultOutputVars();
	
	// red - exit early
	if (answer_list[2].includes("unconscious")){
		return {
			'zone': zone_list[3], 
			'reviewFlag': true
		};
	}
	// yellow_consider
	if (answer_list[0]==="yes" ||
	checkStringForMultiple(2,answer_list[2],["slurred_speech","face_asym","weakness","fever","urination","lightheaded"])){
		zone_result = zone_list[1];
	}
	// hf review scenarios
	if (answer_list[2].includes("lightheaded")){
		review_flag = true;
	}

	return {
		'zone': zone_result, 
		'reviewFlag': review_flag
	};
}

// leg swelling
export function getResultLegSwelling(answer_list){
	var {zone_result, review_flag} = setDefaultOutputVars();
	
	// yellow_consider (no hf review)
	if (answer_list[2]=="yes" || answer_list[3]=="one") {
		zone_result = zone_list[1];
	}
	// hf review scenarios
	if (answer_list[3]=="both" || answer_list[4]=="yes"){
		review_flag = true;
	}
	
	return {
		'zone': zone_result, 
		'reviewFlag': review_flag
	};
}

// lightheadedness
export function getResultLightheaded(answer_list){
	var {zone_result, review_flag} = setDefaultOutputVars();
	
	// yellow_consider
	if (answer_list[0]==="yes" ||
	answer_list[1]==="yes" ||
	answer_list[2]==="yes") {
		zone_result = zone_list[1]
	}
	
	return {
		'zone': zone_result, 
		'reviewFlag': review_flag
	};
}














