const homepageTranslations = {
	'en': {
		"basics": "Basics",
		"symptoms": "Symptoms",
		"m_m": "Management and Monitoring",
		"traffic": "Traffic Light Tool",
    "test_text": "This is some test text for translations",
		"login-button":"Sign in"
	},
	'es': {
		"basics": "Lo Esencial",
		"symptoms": "Síntomas",
		"m_m": "Monitoreo y control",
		"traffic": "Herramienta de luces del semáforo",
        "test_text": "Este es un texto de prueba para las traducciones",
		"login-button":"identificarse"
	}
}

export { homepageTranslations };