const mmsTranslations = {
	'en': {
		"title": "Monitoring and Managing Heart Failure",
		"title_helptext": "Cases that review how to monitor and respond to patients' symptoms in the home",
		"cases": "Aspects of Daily Care", 
		"emergency": "Handling Symptom Changes"
	},
	'es': {
		"title": "Monitoreo y control de la insuficiencia cardíaca",
		"title_helptext": "Casos que revisan cómo monitorear y responder a los síntomas de los pacientes en casa",
		"cases": "Aspecto del cuidado diario", 
		"emergency": "Manejo del cambio de síntomas"
	}
}

export { 
	mmsTranslations};