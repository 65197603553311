import React from 'react';
import './ContentCard.scss';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie'; 


export default function ContentCard(props){
	const history = useHistory();
	const [cookies1, setCookie1,] = useCookies(['uid']);

	function redirect(){
		/*
		if (typeof cookies1.uid !== 'undefined'){
			ReactGA.set({ userId: cookies1.uid });
		} */
		history.push(props.redirect_url)
		console.log("LOOK HERE")
		console.log(props.two_lines)
		console.log(props.two_lines == null)
	}
	
	return(
		<div className="ContentCard" onClick={redirect}>
			<div className="text">
				<div className={props.two_lines == null? "" : "two-lines"}>
					{props.name}
				</div>
			</div>
		</div>
	);

}