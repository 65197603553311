import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../libs/contextLib";

import './Symptoms.scss';
import {symptomsTranslations} from '../../translations/symptomsTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import ContentCard from '../../components/ContentCard';
import PageTitle from '../../components/PageTitle';

export default function Basics(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			// ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		setIsLoading(false);
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);


	return (
		!isLoading && (
			<div className={"ContentPage Symptoms "+screen_size}>
				<PageTitle 
					title={symptomsTranslations[language]['title']}
					title_helptext={symptomsTranslations[language]['title_helptext']}
				/>
				<br/><br/>
			<div className="CardContainer">
			<ContentCard 
					name={symptomsTranslations[language]['commonsymptoms']}
					redirect_url="/symptoms/common-symptoms"
				/>
				<ContentCard 
					name={symptomsTranslations[language]['shortnessbreath']}
					redirect_url="/symptoms/shortness-breath"
				/>
				<ContentCard 
					name={symptomsTranslations[language]['lbswelling']}
					redirect_url="/symptoms/lb-swelling"
				/>
				
				
				<ContentCard 
					name={symptomsTranslations[language]['fatigue']}
					redirect_url="/symptoms/fatigue"
				/>
				<ContentCard 
					name={symptomsTranslations[language]['chestdiscomfort']}
					redirect_url="/symptoms/chest-discomfort"
				/>
				
				
			</div>

		</div>
		)
    );
}