import React, {useEffect} from 'react';
import { useAppContext } from "../libs/contextLib";


import { IntlProvider } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { contactTranslations } from '../translations/contactTranslations';

import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

export default function Contact() {
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
	}, []);

	const { language } = useAppContext();
	return (
		<IntlProvider locale={language} messages={contactTranslations[language]} >
			<div className="ContentPage">
				<h3><FormattedMessage id="contact_us"/></h3>
				<p><FormattedMessage id="contact_blurb"/></p>
				<br/><br/>
				
			</div>
		</IntlProvider>
	)
}