import React from 'react';
// import { useState, useHistory } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from "./../../libs/contextLib";
import './SurveyResultZone.scss';
import { trafficResultsTrans, trafficResultsMonitorTrans } from './../../translations/trafficToolTranslations';
import { FormattedMessage } from 'react-intl';
import ScenarioBox from '../../components/ScenarioBox';

export default function SurveyResultZone(props){
	// const history = useHistory();
	const { language, mainSymptom } = useAppContext();
	
	return(
		<div className="SurveyResultZone">
			{ (props.result.zone == "green") && 
				<div className='green_zone'>
					<h1><FormattedMessage id = {trafficResultsTrans[language]["green_title"]} /></h1>
					<h2><FormattedMessage id = {trafficResultsTrans[language]["green_action"]} /></h2>
					<p><FormattedMessage id = {trafficResultsTrans[language]["green_desc"]} /></p>
				</div> }
			{ (props.result.zone.includes("yellow")) && 
				<div className='yellow_zone'>
					<h1><FormattedMessage id = {trafficResultsTrans[language]["yellow_title"]} /></h1>
					{ (props.result.zone == "yellow_consider") &&
						<div>
							<h2><FormattedMessage id = {trafficResultsTrans[language]["yellow_action_consider"]} /></h2>
							<p><FormattedMessage id = {trafficResultsTrans[language]["yellow_desc_consider"]} /></p>
						</div> }
					{ (props.result.zone == "yellow_call") &&
						<div>
							<h2><FormattedMessage id = {trafficResultsTrans[language]["yellow_action_call"]} /></h2>
							<p><FormattedMessage id = {trafficResultsTrans[language]["yellow_desc_call"]} /></p>
						</div> }
				</div> 
			}
			{/* green and yellow zones have symptom-specific monitoring info */}
			{ (props.result.zone == "green" || props.result.zone.includes("yellow")) &&
				<div>
					<h5><FormattedMessage id = {trafficResultsMonitorTrans[language]["monitoring"]} /></h5>
					{ mainSymptom == "sob" && 
						<div>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["sob_walk"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["sob_walk_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["sob_vitals"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["sob_vitals_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["sob_leg"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["sob_leg_action"]} /></p>
						</div> }
					{ mainSymptom == "chest_discomfort" && 
						<div>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["chest_vitals"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["chest_vitals_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["chest_new"]} /></h3>
						</div> }
					{ mainSymptom == "fatigue" && 
						<div>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["fatigue_vitals"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["fatigue_vitals_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["fatigue_other"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["fatigue_other_action"]} /></p>
						</div> }
					{ mainSymptom == "weight_change" && 
						<div>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["weight_check"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["weight_check_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["weight_other"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["weight_other_action"]} /></p>
						</div> }
					{ mainSymptom == "confusion" && 
						<div>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["conf_vitals"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["conf_vitals_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["conf_other"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["conf_other_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["conf_uncommon"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["conf_uncommon_action"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["conf_hf"]} /></h3>
						</div> }
					{ mainSymptom == "leg_swelling" && 
						<div>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["leg_indent"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["leg_indent_severe"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["leg_ask"]} /></h3>
							<p></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["leg_vitals"]} /></h3>
							<p></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["leg_other"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["leg_other_action"]} /></p>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["leg_not_other_action"]} /></p>
						</div> }
					{ mainSymptom == "lightheaded" &&  
						<div>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["light_near"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["light_near_detail"]} /></p>
							<h3><FormattedMessage id = {trafficResultsMonitorTrans[language]["light_vitals"]} /></h3>
							<p><FormattedMessage id = {trafficResultsMonitorTrans[language]["light_vitals_action"]} /></p>
						</div> }
				</div>
			}
			{ (props.result.zone == "red") && 
				<div>
					<div className="red_zone"> 
						<h1><FormattedMessage id = {trafficResultsTrans[language]["red_title"]} /></h1>
						<h2><FormattedMessage id = {trafficResultsTrans[language]["red_action"]} /></h2>
						<p><FormattedMessage id = {trafficResultsTrans[language]["red_desc"]} /></p>
					</div>
					{/* what to do while waiting */}
					<h5><FormattedMessage id = {trafficResultsTrans[language]["waiting"]} /></h5>
					<p><FormattedMessage id = {trafficResultsTrans[language]["wait_vitals"]} /></p>
					<p><FormattedMessage id = {trafficResultsTrans[language]["wait_family"]} /></p>
					<p><FormattedMessage id = {trafficResultsTrans[language]["wait_super"]} /></p>
				</div> }

			{/* recommend reviewing hf materials if flag is set */}
			{ (props.result.reviewFlag == true) && 
				<div>
					<br></br>
					
					<h5><FormattedMessage id = {trafficResultsTrans[language]["review_resources"]} /></h5>
					<p><FormattedMessage id = {trafficResultsTrans[language]["review_desc"]} /></p>
				</div> }
			
			<br></br><br></br>
		</div>
	);

}