import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../../libs/contextLib";

import CaseSwelling from './CaseSwelling';

import {caseSwellingTrans} from '../../../translations/casesTranslations';

export default function CaseSwellingContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={caseSwellingTrans[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<CaseSwelling size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<CaseSwelling size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}