// Auth
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Hooks
import { useCookies } from 'react-cookie';
import ReactGA from 'react-ga4';
import { useAppContext } from "../../libs/contextLib";
import { useFormFields } from '../../libs/hooksLib';
import { onError } from '../../libs/errorLib';

// Auth
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LoaderButton from '../../components/LoaderButton';
import { Modal } from 'react-bootstrap';
import { Form, FormInput, Button } from "shards-react";

import { PHONE_TO_ID, ID_TO_NAME } from '../../PARTICIPANT_MAPPING.js';

import './Auth.scss';


export default function AuthModal(){
	// Other setup
	const history = useHistory();

	// hooks
	const { 
		userHasAuthenticated, 
		username, setUsername,
		setTrackingIssue,
		showAuthModal, setShowAuthModal, 
		language
	} = useAppContext();
	const [isLoading, setIsLoading] = useState(true);
	const [fields, handleFieldChange] = useFormFields({ phone_number: ""});

	// There are three possible modal views: 
	// 1) sign in view - in this view if inSignup=false
	// 2a) sign up view - in tis view if inSignup=true, inScreenName=false
  	const [signedIn, setSignedIn] = useState(false);
  	// Error Flagging for each view; one error message var is enough bc we update every time
  	const [hasError, setHasError] = useState(false);
  	const [errorMessage, setErrorMessage] = useState('');

	
	const [cookies1, setCookie1, removeCookie1] = useCookies(['uid']);


	  useEffect(() => {
	    onLoad();
	  }, []);

	  async function onLoad() {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			const name = ID_TO_NAME[cookies1.uid]
			await setUsername(name);
			await setSignedIn(true);
		}
	    setIsLoading(false);
	  }

  	// Handlers for Auth Modal Display
	const handleClose = () => { // When we close modal we want to close everything
		setShowAuthModal(false); // updates context
		setHasError(false);
		setIsLoading(false);
		setErrorMessage('')
	}

	// Validating Form Fields
	function validateLogin(){
		// strip field
		const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
		var stripped_number = fields.phone_number.replace(regex, '');
    	return stripped_number.length === 10;
  	}




  	// Auth Functions
  	async function handleLogin(e){ // non-social DRF JWT Token login
    	e.preventDefault();
    	setIsLoading(true);

    	const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
    	var stripped_number = fields.phone_number.replace(regex, '');

    	const id = PHONE_TO_ID[stripped_number]

    	if (typeof id == 'undefined'){
    		setHasError(true);
    		setErrorMessage("This phone number doesn't exist, please try again or call for help")
    	} else {
    		const name = ID_TO_NAME[id]
    		await setUsername(name)
    		await setCookie1('uid', id, {path:"/", maxAge: 120*86400});
    		await setSignedIn(true);
    	}
    	

	    setIsLoading(false);
    }

	function gotoContactPage(){
		history.push('/contact');
		setShowAuthModal(false);
	}


	async function handleLogout(){
		console.log("called")
		setIsLoading(true);
		//ReactGA.set({ userId: undefined});
		ReactGA.gtag("set", "user_properties", {
			user_id: undefined,
			user_report_id: undefined
		});
    	await setTrackingIssue(true);
    	await removeCookie1('uid');
    	await setUsername("");
    	await setSignedIn(false);
    	setIsLoading(false);
  	}



	return (
		<div className="AuthModal">
			
			<Modal animation={false} id="auth-modal" show={showAuthModal} onHide={handleClose}>
				<Modal.Header>
					<div className="modal-close-button">
						<i onClick={handleClose}>
							<FontAwesomeIcon icon="times" size="1x"/>
						</i>
					</div>
			</Modal.Header>
				
				{!signedIn && <Modal.Body>
	  				<h2>
	  					{language === "es" ? "Ingrese su número de teléfono" : "Input your phone number"}
	  				</h2>
	  				<p>
					  {language === "es" ? "Esto permite al estudio identificarlo y rastrear su uso" : "This allows the study to identify you and track your usage"}
	  				</p>
	              	{ hasError && 
	              		<div className="emphasized-orange">{ errorMessage }</div>
	              	}
	              	<Form onSubmit={handleLogin}>
	                  	<FormInput 
	                  		id = "phone_number" value={fields.phone_number} onChange={handleFieldChange} 
	                  		placeholder={"eg. 111-111-1111"} className="mb-2" type="text"/>
	                  	<div className="forgot-password">
	                  
	                  	{ language === "es" ? 
	                  		"Llame a su coordinador si tiene alguna pregunta" : 
	                  		"Please call your coordinator if you have any questions" }
	                  	</div>
	                  	<LoaderButton 
	                  		type="submit" label={language === "es" ? "Actualización" : "Update"}
	                  		disabled={!validateLogin()} isLoading={isLoading}
	                  	/>
	              	</Form>
  				</Modal.Body>}

  				{signedIn && <Modal.Body>
	  				<p>
					  {language === "es" ? "¡Ya está inscrito! Inscrito como" : "You're signed in! Signed in as"}
	  				</p>
	  				<h2>
	  					{username}
	  				</h2>
	  					
                  	<LoaderButton 
                  		type="standalone"
                  		label={language === "es" ? "¿No es usted? Inicie sesión nuevamente" : "Not you? Log in again"}
                  		disabled={false} isLoading={isLoading}
                  		onClick={handleLogout}
                  	/>


  				</Modal.Body>
  				}
  				
  
  				<Modal.Footer>
  					<div>
  						
                	</div>

  				</Modal.Footer>
  			</Modal>



		</div>
	);
}