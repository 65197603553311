import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Routes from './Routes';
import { BreakpointProvider, Breakpoint} from 'react-socks';
import { useCookies } from 'react-cookie';

// helper imports
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib"; 

// Styling imports
import CustomNav from './components/CustomNav';
import CustomFooter from './components/CustomFooter';
import AuthModal from './containers/Auth/AuthModal';
import {ID_TO_NAME} from './PARTICIPANT_MAPPING';

import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/shards-dashboards.1.1.0.min.scss";
import './App.scss';
import './styles/theme_styles.scss';


// Icons
import { library } from "@fortawesome/fontawesome-svg-core";
// general
import { 
  faCheck, faUser, faSpinner, faTimes, faAngleDoubleRight, 
  faCheckSquare, faCheckCircle, faExclamationTriangle, faTimesCircle,
  faCertificate, faMousePointer
} from '@fortawesome/free-solid-svg-icons';

import ReactGA from 'react-ga4';

function App() {
  // general use icons
  library.add(
    faCheck, faUser, faSpinner, faTimes, faAngleDoubleRight, 
    faCheckSquare, faCheckCircle, faExclamationTriangle, faTimesCircle,
    faCertificate, faMousePointer
  );

  const [cookies, setCookie,] = useCookies(['f1_lang']);
  const [cookies1, setCookie1,] = useCookies(['uid']);
  const [language, setLanguage] = useState('ENG');
  const [username, setUsername] = useState('');
  const [trackingIssue, setTrackingIssue] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const queryString = require('query-string');
  const uid = queryString.parse(useLocation().search).id;

  // context variables for traffic light
  const [trafficLightAnswers, setTrafficLightAnswers] = useState(null);
  const [mainSymptom, setMainSymptom] = useState(null);
  const [checkedState, setCheckedState] = useState(null);



  useEffect(() => {
    onLoad();

  }, []);

  async function onLoad() {
    await getLanguage();
    window.scrollTo(0, 0)

    if (typeof uid == 'undefined' && typeof cookies1.uid == 'undefined'){
      await setTrackingIssue(true);
    } else if (typeof uid !== 'undefined'){
      await setCookie1('uid', uid, {path:"/"});
      setUsername(ID_TO_NAME[uid])
      ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
      // code from google 
      // ga('set', 'userId', 'USER_ID'); // Set the user ID using signed-in user_id.
    } else {
      setUsername(ID_TO_NAME[cookies1.uid]);
      ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
    }

    console.log("ID IS: ")
    console.log(uid)

  }

  async function getLanguage() {
    console.log(navigator.language.substring(0, 2));;
    if (typeof cookies.f1_lang !== 'undefined'){
      await setLanguage(cookies.f1_lang);
    } else { // we only set lang to not chinese if user explicitly has english browser
      if (navigator.language.substring(0, 2) === "es") {
        await setLanguage("es")
        await setCookie('f1_lang', "es", {path: "/"});
      } else {
        await setLanguage("en")
        await setCookie('f1_lang', "en", {path: "/"});
      }
    }
  }


  return (
    (
      <BreakpointProvider>
      <div className="App">
        <AppContext.Provider value = {{ 
          language, setLanguage,
          username, setUsername,
          showAuthModal, setShowAuthModal,
          trackingIssue, setTrackingIssue,
          trafficLightAnswers, setTrafficLightAnswers,
          mainSymptom, setMainSymptom,
          checkedState, setCheckedState
        }}>

          
          <Breakpoint medium up>
            <CustomNav size="MediumUp"/>
          </Breakpoint>
          <Breakpoint small down>
            <CustomNav size="SmallDown"/>
          </Breakpoint>
          
          <div className="AppContent">
            <AuthModal/>
            <Routes/>            
          </div>
          <CustomFooter/>
        </AppContext.Provider>
      </div>
      </BreakpointProvider>
    )

  );
}

export default App;
