import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "./../../../libs/contextLib";

import TrafficTool from './TrafficTool';

import { trafficToolTranslations } from './../../../translations/trafficToolTranslations'

export default function TrafficToolContainer(){
	const { language } = useAppContext();

	return (
    	<IntlProvider locale={language} messages={trafficToolTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<TrafficTool size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only> 
					<TrafficTool size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}