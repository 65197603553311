import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import HttpsRedirect from 'react-https-redirect';

import { GOOGLE_TRACKING_ID,GA4_MEASUREMENT_ID } from './GLOBAL_VARIABLES'
// Google analytics
import ReactGA from 'react-ga4';
ReactGA.initialize(GA4_MEASUREMENT_ID);


ReactDOM.render(
  <Router>
    <HttpsRedirect>
      <App />
    </HttpsRedirect>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
