import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../libs/contextLib";

import MMs from './MMs';

import { mmsTranslations } from '../../translations/mmsTranslations'

export default function MMContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={mmsTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<MMs size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<MMs size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}