import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../../libs/contextLib";

import '../EmergPages.scss';
import {emergChestPainTrans} from '../../../translations/emergTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import PageTitle from '../../../components/PageTitle';
import ScenarioBox from '../../../components/ScenarioBox';

export default function EmergChestPain(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		window.scrollTo(0, 0)
		setIsLoading(false);
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	return (
		!isLoading && (<div className={"ContentPage EmergPage "+screen_size}>
			<PageTitle 
				title={emergChestPainTrans[language]['title']}
			/>
			<ScenarioBox text={emergChestPainTrans[language]['overview']}/>

			<div className={"content"}>
				<h3><FormattedMessage id="h1"/></h3>
				<p><FormattedMessage id="h1p1"/></p>
				<p><FormattedMessage id="h1p2"/></p>

				<h3><FormattedMessage id="h2"/></h3>
				<p><FormattedMessage id="h2p1"/></p>
				<p className = 'sub'><FormattedMessage id="h2p1-sub"/></p>
				<p><FormattedMessage id="h2p2"/></p>
				<p><FormattedMessage id="h2p3"/></p>

				<ScenarioBox text={emergChestPainTrans[language]['h3']}/>
				<h3><FormattedMessage id="h3-h1"/></h3>
				<p><FormattedMessage id="h3p1"/></p>
				<h3><FormattedMessage id="h3-h2"/></h3>
				<p className = 'sub'><FormattedMessage id="h3p2-sub"/></p>
			</div>

		</div>)
    );
}