import React from 'react';
import { useAppContext } from "../libs/contextLib";

export default function PrivacyPolicy(){
	const { language } = useAppContext();
	return (
		<div class="ContentPage">
			{ language === "es" ? 
			<span>
				<h1>POLÍTICA DE PRIVACIDAD</h1>
				<p>Última actualización: 14 de junio de 2021</p>
				<br/>
				<p>Esta Política de Privacidad describe nuestras políticas y procedimientos con respecto a la obtención, uso y divulgación de su información cuando usa el servicio y le dice sobre sus derechos de privacidad y cómo lo protege la ley.</p>
			</span>:
			<span>
				<h1>PRIVACY POLICY</h1>
				<p>Last updated: June 14, 2021</p>
				<br/>
				<p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>
			</span>
			}
		</div>

	);
}