import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../libs/contextLib";

import Emerg from './Emerg';

import { emergTranslations } from '../../translations/emergTranslations'

export default function EmergContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={emergTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<Emerg size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<Emerg size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}