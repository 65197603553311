import React from 'react';
import './PageTitle.scss';
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie'; 
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function PageTitle(props){
	const history = useHistory();
	const [cookies1, setCookie1,] = useCookies(['uid']);

	function redirect(){
		/*
		if (typeof cookies1.uid !== 'undefined'){
			ReactGA.set({ userId: cookies1.uid });
		} */
		history.push(props.redirect_url)
	}
	
	return(
		<div className="PageTitleDiv">
			<h1>{props.title}</h1>
			<h5>{props.title_helptext}</h5>
		</div>
	);

}