import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "./../../../libs/contextLib";

import TrafficLanding from './TrafficLanding';

import { trafficLandingTranslations } from './../../../translations/trafficLandingTranslations'

export default function TrafficLandingContainer(){
	const { language } = useAppContext();

	return (
    	<IntlProvider locale={language} messages={trafficLandingTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<TrafficLanding size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only> 
					<TrafficLanding size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}