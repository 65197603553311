import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../../libs/contextLib";

import '../CasePages.scss';
import {caseVisitTrans} from '../../../translations/casesTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import PageTitle from '../../../components/PageTitle';
import ScenarioBox from '../../../components/ScenarioBox';

import './CaseVisit.scss';

export default function CaseVisit(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		window.scrollTo(0, 0)
		setIsLoading(false);
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	return (
		!isLoading && (<div className={"ContentPage CasePage "+screen_size}>
			<PageTitle 
				title={caseVisitTrans[language]['title']}
			/>
			<ScenarioBox text={caseVisitTrans[language]['overview']}/>
			<div className={"content"}>
				<h3><FormattedMessage id="h1"/></h3>
				<p><FormattedMessage id="h1p1"/></p>
				<p><FormattedMessage id="h1p2"/></p>
				<p><FormattedMessage id="h1p3"/></p>

				<h3><FormattedMessage id="h2"/></h3>
				<p><FormattedMessage id="h2p1"/></p>
				<p><FormattedMessage id="h2p2"/></p>
				<p><FormattedMessage id="h2p3"/></p>
			</div>

		</div>)
    );
}