import React from 'react';
// import { useState, useHistory } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import SurveyResultSummary from './SurveyResultSummary';
import SurveyResultZone from './SurveyResultZone';
import { useAppContext } from '../../libs/contextLib';
import { answerString2List } from './../../libs/trafficLib';
import { 
	getResultSob, getResultChestDiscomfort, getResultFatigue, getResultWeightChange, 
	getResultConfusion, getResultLegSwelling, getResultLightheaded
} from './../../libs/trafficResultLib';

export default function SurveyResultPage(props){
	// const history = useHistory();
	const { language, mainSymptom, trafficLightAnswers } = useAppContext();
	const answerList = answerString2List(trafficLightAnswers);
	// const [surveyOutput, setSurveyOutput] = useState({});
				
	return(
		<div className="SurveyResultPage">
			
			{console.log(mainSymptom)}

			{ mainSymptom =='sob' && 
				<SurveyResultZone result={getResultSob(answerList)} />}
			{ mainSymptom =='chest_discomfort' && 
				<SurveyResultZone result={getResultChestDiscomfort(answerList)} />}
			{ mainSymptom =='fatigue' && 
				<SurveyResultZone result={getResultFatigue(answerList)} />}
			{ mainSymptom =='weight_change' && 
				<SurveyResultZone result={getResultWeightChange(answerList)} />}
			{ mainSymptom =='confusion' && 
				<SurveyResultZone result={getResultConfusion(answerList)} />}
			{ mainSymptom =='leg_swelling' && 
				<SurveyResultZone result={getResultLegSwelling(answerList)} />}
			{ mainSymptom =='lightheaded' && 
				<SurveyResultZone result={getResultLightheaded(answerList)} />}
		
			{/* <SurveyResultSummary/> */}
			
		</div>
	);

}