const trafficLandingTranslations = {
	'en': {
		"title": "Traffic Light Tool",
		"basics": "Basics",
		"overview": "Use this tool to help you assess your patient’s symptoms in real time. ",
		"how_to_title": "How to use the tool:", 
		"how_to_text": "First, you will be asked to answer a couple of questions about your patient’s most concerning symptom. Then, the tool will show which zone the patient’s symptoms are in. ",
		"review_title": "Review of the Traffic Light System",
		"review_text1": "The green zone means that the patient is stable.",
		"review_text2": "The yellow zone means you’ve noticed some changes or something new that concerns you.",
		"review_text3": "The red zone means the patient’s symptoms are worsening and the changes are worrisome.",
		"goto_tool": "Click here to start"
	},
	'es': {
		"title": "Herramienta de luces del semáforo",
		"basics": "Información básica",
		"overview": "Use esta herramienta para ayudarse a evaluar los síntomas de su paciente en tiempo real.",
		"how_to_title":"Cómo usar la herramienta:",
		"how_to_text":"Primero se le pedirá que responda un par de preguntas sobre el síntoma más preocupante del paciente. Luego, la herramienta indicará en qué zona están los síntomas del paciente. ",
		"review_title":"Revise el sistema de luces del semáforo",
		"review_text1":"La zona verde significa que el paciente está estable.",
		"review_text2":"La zona amarilla significa que ha observado algunos cambios o algo nuevo que le preocupa",
		"review_text3":"La zona roja significa que los síntomas del paciente están empeorando y los cambios son preocupantes.",
		"goto_tool":"Haga clic aquí para empezar"
	}
}

export { trafficLandingTranslations };