import React from 'react';

export default function PrivacyPolicy(){
  return (
    <div className="ContentPage">
      <h1>TERMS & CONDITIONS</h1>
      <p>Last updated: June 14, 2021</p>
      <br/>
      <h3>Agreement To Terms</h3>
      <p>
        TBU.
      </p>


    </div>
  );
}