import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../libs/contextLib";

import Cases from './Cases';

import { casesTranslations } from '../../translations/casesTranslations'

export default function CasesContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={casesTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<Cases size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<Cases size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}