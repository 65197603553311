import React from 'react';
import './IconCard.scss';
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie'; 
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css'


export default function IconCard(props){
	const history = useHistory();
	const [cookies1, setCookie1,] = useCookies(['uid']);

	function redirect(){
		/*
		if (typeof cookies1.uid !== 'undefined'){
			ReactGA.set({ userId: cookies1.uid });
		} */
		history.push(props.redirect_url) 
	}
	// var lastRow = props.last
 //    if (lastRow === "true"){
 //        return(
 //            <div class="col-md-6 center-block" onClick={redirect}>
 //                <img src = {props.img} class="card-img-top" alt="Loading img"></img>
 //                <div class="card-body">
 //                    <p class="card-text">{props.name}</p>
 //                </div>
 //            </div>
 //        );
 //    }
	return(
		<div class="IconCard" onClick={redirect}>
            <img src = {props.img} class="card-img-top" alt="Loading img"></img>
            <p class="card-text">{props.name}</p>
        </div>
	);

}