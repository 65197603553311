import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../../libs/contextLib";

import '../CasePages.scss';
import {caseBPTrans} from '../../../translations/casesTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import PageTitle from '../../../components/PageTitle';
import ContentCard from '../../../components/ContentCard';
import ScenarioBox from '../../../components/ScenarioBox';

import './CaseBP.scss';

export default function CaseBP(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			 });
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		window.scrollTo(0, 0)
		//console.log(language)
		setIsLoading(false);

	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	return (
		!isLoading && (<div className={"ContentPage CasePage "+screen_size}>
			<PageTitle 
				title={caseBPTrans[language]['title']}
			/>
			<div className={"content"}>
				<ul>
					<li><p><FormattedMessage id="bp-helper-text"/></p></li>
				</ul>
				<h3><FormattedMessage id="h2"/></h3>
				{language === 'es'? 
					<img src = "/case_img/Blood_pressure_levels_s.jpg" className = "case_img"></img>:
					<img src = "/case_img/Blood_pressure_levels.JPG" className = "case_img"></img>}
				<h3><FormattedMessage id="h1"/></h3>
				{language === 'es'? 
					<img src = "/case_img/take_blood_pressure_s.jpg" className = "case_img"></img>:
					<img src = "/case_img/take_blood_pressure.jpg" className = "case_img"></img>}
				<ul>
					<li><p><FormattedMessage id="h1p1"/></p></li>
					<li><p><FormattedMessage id="h1p2"/></p></li>
					<li><p><FormattedMessage id="h1p3"/></p></li>
					<li><p><FormattedMessage id="h1p4"/></p></li>
					<li><p><FormattedMessage id="h1p5"/></p></li>
					<li><p><FormattedMessage id="h1p6"/></p></li>
					<li><p><FormattedMessage id="h1p7"/></p></li>
					<li><p><FormattedMessage id="h1p8"/></p></li>
				</ul>

				<ContentCard 
					name={<FormattedMessage id="button-text"/>}
					redirect_url="/cases/blood-pressure-2"
				/>
			</div>

		</div>)
    );
}