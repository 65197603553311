const example_string = "yes:severe,leg_swelling+chest_pain:severe+fever+mucus,no,yes,no";

//**** Parsing 1st level, the commas ********//
export function answerString2List(answer_string){
	return answer_string.split(",")
}

function updateAnswerList(answer_list, question_id, answer){ 
	// assume: answer is in correct schema format, eg "leg_swelling+chest_pain:severe+fever+mucus"
	answer_list[question_id] = answer
	return answer_list
}

export function answerList2String(answer_list){
	var answer_string = ""
	for (var i = 0; i < answer_list.length; i++){
		answer_string += answer_list[i]+","
	}
	answer_string = answer_string.slice(0, -1) // remove the last comma
	return answer_string
}

export function updateAnswerString(answer_string, question_id, answer){
	var answer_list = answerString2List(answer_string)
	answer_list = updateAnswerList(answer_list, question_id, answer)
	return answerList2String(answer_list)
}

export function getAnswerFromString(answer_string, question_id){
	const answer_list = answerString2List(answer_string)
	return answer_list[question_id]
}

//*******************************************//

//**** Parsing MC - the pluses and colons ********//
export function mcAnswers2ListAndDict(mc_string){
	if (mc_string == ""){
		return [[], {}]
	}

	const mc_list = mc_string.split("+")
	var mc_options_list = [] // each element is an option
	var mc_conditionals_dict = {} // key is option, value is selected conditional value
	for (var i = 0; i < mc_list.length; i++){
		const option_split = mc_list[i].split(":")
		if (option_split.length <= 1){
			// there is no conditional argument
			mc_options_list.push(option_split)
		} else {
			mc_options_list.push(option_split[0])
			mc_conditionals_dict[option_split[0]] = option_split[1]
		}
	} 

	return [mc_options_list, mc_conditionals_dict]
}

function mcListAndDict2Answers(mc_options_list, mc_conditionals_dict){
	console.log('mc conditional dict', mc_conditionals_dict)
	var mc_string = ""
	var option = null
	for (var i = 0; i < mc_options_list.length; i++){
		console.log("LOOK HERE 2")
		console.log("i = "+i)
		console.log(mc_conditionals_dict)
		option = mc_options_list[i]
		mc_string += option
		if (option in mc_conditionals_dict){
			console.log("IN IFFFF")
			mc_string += ":"
			mc_string += mc_conditionals_dict[option]
		}
		mc_string += "+"
	}
	mc_string = mc_string.slice(0, -1) // remove final + sign in string
	return mc_string
}

function updateMcListAndDict(
	// assume all conditionals are SELECTCHOICES
	mc_options_list, mc_conditionals_dict, 
	e_target_id, e_target_value, 
	is_conditional, conditional_key
	){

	var new_mc_options_list = mc_options_list
	var new_mc_conditionals_dict = mc_conditionals_dict

	if (is_conditional){
		new_mc_conditionals_dict[conditional_key] = e_target_value
	} else if (!is_conditional && !e_target_value) { // not conditional, unchecked
		if (e_target_id in new_mc_conditionals_dict){
			delete new_mc_conditionals_dict[e_target_id]
		}	
		new_mc_options_list.splice(new_mc_options_list.indexOf(e_target_id), 1)
	} else if (!is_conditional && e_target_value){
		new_mc_options_list.push(e_target_id)
	}

	return [new_mc_options_list, new_mc_conditionals_dict]
}

export function updateMcString(
	mc_string, 
	e_target_id, e_target_value, is_conditional, conditional_key
	){
	var [mc_options_list, mc_conditionals_dict] = mcAnswers2ListAndDict(mc_string)
	var [new_mc_options_list, new_mc_conditionals_dict] = updateMcListAndDict(mc_options_list, mc_conditionals_dict, e_target_id, e_target_value, is_conditional, conditional_key)

	return mcListAndDict2Answers(new_mc_options_list, new_mc_conditionals_dict)
}

//*******************************************//

//**** Parsing YESNO/SELECTCHOICE - just the colons ********//
function selectString2TupleList(select_string){
	return select_string.split(":")
}

function selectTuple2String(select_list){
	if (select_list[1] == null){ // no conditional
		return select_list[0]
	} else { // there is a SELECTCHOICE conditional
		return select_list[0]+":"+select_list[1]
	}
}

function updateSelectTupleList(select_list, e_target_value, is_conditional){

	var new_tuple_list = [null, null]
	if (is_conditional){
		new_tuple_list[0] = select_list[0]
		new_tuple_list[1] = e_target_value
	} else {
		new_tuple_list[0] = e_target_value
	}

	return new_tuple_list
}


export function updateSelectString(
	select_string, e_target_value, is_conditional){

	const select_list = selectString2TupleList(select_string)
	const updated_list = updateSelectTupleList(select_list, e_target_value, is_conditional)

	return selectTuple2String(updated_list)
}





















