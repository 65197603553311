import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../../libs/contextLib";

import '../CasePages.scss';
import {caseMedicationTrans} from '../../../translations/casesTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';
import PageTitle from '../../../components/PageTitle';
import ScenarioBox from '../../../components/ScenarioBox';

export default function CaseMedication(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//console.log(language)
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		setIsLoading(false);
		window.scrollTo(0, 0)
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	return (
		!isLoading && (<div className={"ContentPage CasePage "+screen_size}>
			<PageTitle 
				title={caseMedicationTrans[language]['title']}
			/>
			<div className={"medication-content"}>
				<ul><li><p><FormattedMessage id="m-helper-text"/></p></li></ul>
				<h3><FormattedMessage id="h1"/></h3>
				<p><FormattedMessage id="h1p1"/></p>
				<p><FormattedMessage id="h1p2"/></p>
				<p><FormattedMessage id="h1p3"/></p>
				<p><FormattedMessage id="h1p4"/></p>

				<h3><FormattedMessage id="h2"/></h3>
				<p><FormattedMessage id="h2p1"/></p>
				<p><FormattedMessage id="h2p2"/></p>
				<p><FormattedMessage id="h2p3"/></p>
				<p><FormattedMessage id="h2p4"/></p>

				<h3><FormattedMessage id="h3"/></h3>
				<p><FormattedMessage id="h3p1"/></p>
				<p><FormattedMessage id="h3-header"/></p>
				<ScenarioBox text={caseMedicationTrans[language]['lasix-info']}/>

				<h3><FormattedMessage id="h4"/></h3>
				<p><FormattedMessage id="h4p1"/></p>
				<p><FormattedMessage id="h4p2"/></p>

				<h3><FormattedMessage id="h5"/></h3>
				<p><FormattedMessage id="h5p1"/></p>

			</div>

		</div>)
    );
}