import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../../libs/contextLib";

import HeartFailure from './HeartFailure';

import {heartfailureTranslations} from '../../../translations/basicsTranslations'

export default function HeartFailureContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={heartfailureTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<HeartFailure size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<HeartFailure size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}