import React, {useState, useEffect} from 'react';
import { useAppContext } from "./../../../libs/contextLib";
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import './TrafficTool.scss';
import {trafficToolTranslations, trafficQuestionTrans, trafficSymptomsTrans} from './../../../translations/trafficToolTranslations';

import {
	NUM_QUESTIONS_PER_SYMPTOM, REQUIRED_QS_PER_SYMPTOM, MC_QUESTION_SIZE,
	GetSobQs, GetChestQs, GetFatigueQs, GetLegSwellingQs, 
	GetWeightQs, GetLightheadFallQs, GetConfusionQs
} from './SYMPTOMS_QUESTIONS'
import { answerString2List } from './../../../libs/trafficLib';

import SurveyPage from './../../../components/Survey/SurveyPage';
import SurveyResultPage from './../../../components/Survey/SurveyResultPage';

export default function TrafficTool(props){
	
	// const history = useHistory();
	const class_name = props.size; // this is for screen size only
	const { language } = useAppContext();
	const { trafficLightAnswers, setTrafficLightAnswers } = useAppContext();
	const { mainSymptom, setMainSymptom } = useAppContext();
	const { checkedState, setCheckedState } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);
	const [inFirstQ, setFirstQ] = useState(true);
	const [inSymptomQs, setSymptomQs] = useState(false);
	const [inResults, setResults] = useState(false);

	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			// ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		window.scrollTo(0, 0)
		setIsLoading(false);
	}, []);

	function next(){
		if (inFirstQ){
			setSymptomQs(true);
			setFirstQ(false);
		} else if (inSymptomQs){
			setResults(true);
			setSymptomQs(false);
		}
	}

	function back(){
		if (inSymptomQs){
			setFirstQ(true);
			setSymptomQs(false);
		} else if (inResults){
			setSymptomQs(true);
			setResults(false);
		}
	}
	
	function exit(){
		setSymptomQs(false);
		setFirstQ(false);
		setResults(false);
		window.location = "/trafficlight";
	}

	function updateFirstQ(e){
		setMainSymptom(e.target.value);
		// initiate empty symptom answer string
		setTrafficLightAnswers(",".repeat(NUM_QUESTIONS_PER_SYMPTOM[e.target.value]-1))
		// initialize checkedState
		if (checkedState==null & MC_QUESTION_SIZE[e.target.value]!=-1){
			setCheckedState(new Array(MC_QUESTION_SIZE[e.target.value]).fill(false))
		}
	}

	function submitFirstQ(e){
		setSymptomQs(true);
		setFirstQ(false);
		// check to see if anything is selected
	}

	function allRequiredFieldsFilled(){
		const answerList = answerString2List(trafficLightAnswers)
		const requiredQsIxList = REQUIRED_QS_PER_SYMPTOM[mainSymptom]
		for (let i=0; i<requiredQsIxList.length; i++){
			if (answerList[requiredQsIxList[i]]==="") {
				return false
			}
		}
		return true
	}

	function nextShouldBeDisabled(){
		if (inFirstQ && mainSymptom != null){
			return false
		}
		if (inSymptomQs && allRequiredFieldsFilled()){
			return false
		}
		return true
	}

	// function initializeCheckedState(){
	// 	console.log('inside initializecheckedstate')
	// 	if (checkedState==null & MC_QUESTION[mainSymptom]!=-1){
	// 		setCheckedState(new Array(MC_QUESTION[mainSymptom]).fill(false))
	// 	}
	// }

	return (
		!isLoading && (
		<div className={"ContentPage TrafficTool "+class_name}>
			<div>
				<h1><FormattedMessage id="title"/></h1>
			</div>

			{ inFirstQ && 
				<div>
					<h2><FormattedMessage id="subtitle_qs"/></h2>
					<h3><FormattedMessage id={trafficQuestionTrans[language]["first_q"]}/></h3>
					<form>
						<input type="radio" 
								value="sob" 
								checked={mainSymptom === "sob"}
								onChange={updateFirstQ} 
								name="firstq"/><FormattedMessage id={trafficSymptomsTrans[language]["sob"]}/>
						<br/>
						<input type="radio" 
								value="chest_discomfort" 
								checked={mainSymptom === "chest_discomfort"}
								onChange={updateFirstQ} 
								name="firstq"/><FormattedMessage id={trafficSymptomsTrans[language]["chest_discomfort"]}/>
						<br/>
						<input type="radio" 
								value="fatigue" 
								checked={mainSymptom === "fatigue"}
								onChange={updateFirstQ} 
								name="firstq"/><FormattedMessage id={trafficSymptomsTrans[language]["fatigue"]}/>
						<br/>
						<input type="radio" 
								value="weight_change" 
								checked={mainSymptom === "weight_change"}
								onChange={updateFirstQ} 
								name="firstq"/><FormattedMessage id={trafficSymptomsTrans[language]["weight_change"]}/>
						<br/>
						<input type="radio" 
								value="confusion" 
								checked={mainSymptom === "confusion"}
								onChange={updateFirstQ} 
								name="firstq"/><FormattedMessage id={trafficSymptomsTrans[language]["confusion"]}/>
						<br/>
						<input type="radio" 
								value="leg_swelling" 
								checked={mainSymptom === "leg_swelling"}
								onChange={updateFirstQ} 
								name="firstq"/><FormattedMessage id={trafficSymptomsTrans[language]["leg_swelling"]}/>
						<br/>
						<input type="radio" 
								value="lightheaded" 
								checked={mainSymptom === "lightheaded"}
								onChange={updateFirstQ} 
								name="firstq"/><FormattedMessage id={trafficSymptomsTrans[language]["lightheaded_falls"]}/>
					</form>
					<br/>
				</div>
			}
			
			{ inSymptomQs && 
				<div>
					<h2><FormattedMessage id="subtitle_qs"/></h2>
					{ mainSymptom == "sob" && <SurveyPage survey_page={GetSobQs(language)}/>}
					{ mainSymptom == "chest_discomfort" && <SurveyPage survey_page={GetChestQs(language)}/> }
					{ mainSymptom == "fatigue" && <SurveyPage survey_page={GetFatigueQs(language)}/> }
					{ mainSymptom == "weight_change" && <SurveyPage survey_page={GetWeightQs(language)}/> }
					{ mainSymptom == "confusion" && <SurveyPage survey_page={GetConfusionQs(language)}/> }
					{ mainSymptom == "leg_swelling" && <SurveyPage survey_page={GetLegSwellingQs(language)}/> }
					{ mainSymptom == "lightheaded" &&  <SurveyPage survey_page={GetLightheadFallQs(language)}/> }
				</div>
			}

			{ inResults &&  
				<div className="results">
					<h5><FormattedMessage id={trafficToolTranslations[language]["subtitle_results"]}/></h5>
					<SurveyResultPage/>
				</div>
			}

			{console.log('checkedState',checkedState)}

			{(inSymptomQs || inResults) && <button onClick={back}><FormattedMessage id="back"/></button>}
			&nbsp;&nbsp;
			{(inResults || inFirstQ) && <button onClick={exit}><FormattedMessage id="exit"/></button>}
			&nbsp;&nbsp;
			{(inFirstQ) && <button onClick={next} disabled={nextShouldBeDisabled()}><FormattedMessage id="next"/></button>}
			&nbsp;&nbsp;
			{(inSymptomQs) && <button onClick={next} disabled={nextShouldBeDisabled()}><FormattedMessage id="submit"/></button>}

		</div>
    	));}


