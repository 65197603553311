import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../../libs/contextLib";

import EmergChestPain from './EmergChestPain';

import {emergChestPainTrans} from '../../../translations/emergTranslations';

export default function EmergeChestPainContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={emergChestPainTrans[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<EmergChestPain size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<EmergChestPain size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}