import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../../libs/contextLib";

import '../CasePages.scss';
import './CaseSwelling.scss';
import {caseSwellingTrans} from '../../../translations/casesTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import PageTitle from '../../../components/PageTitle';
import ScenarioBox from '../../../components/ScenarioBox';

export default function CaseSwelling(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		window.scrollTo(0, 0)
		setIsLoading(false);
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	return (
		!isLoading && (<div className={"ContentPage CasePage "+screen_size}>
			<PageTitle 
				title={caseSwellingTrans[language]['title']}
			/>
			<ul>
				<li><p><FormattedMessage id="swelling-helper-text"/></p></li>
				<li><p><FormattedMessage id="h1p3"/><a href="https://palsforhealth.com/Pals/Article/Can%20heart%20failure%20cause%20leg%20swelling?query=HF%20leg%20swelling"><FormattedMessage id="h1p4"/></a></p></li>
			</ul>
			<ScenarioBox text={caseSwellingTrans[language]['overview']}/>

			<div className={"content"}>
				<h3><FormattedMessage id="h1"/></h3>
				<img src = '/case_img/swelling_check.png' className = "case_img"></img>
				<h4><FormattedMessage id="h1-h1"/></h4>
				<ul>
					<li><p><FormattedMessage id="h1-h1-p1"/></p></li>
					<li><p><FormattedMessage id="h1-h1-p2"/></p></li>
				</ul>
				<h4><FormattedMessage id="h1-h2"/></h4>
				<ul>
					<li><p><FormattedMessage id="h1-h2-p1"/></p></li>
				</ul>

				<h3><FormattedMessage id="h3"/></h3>
				<p><FormattedMessage id="h3-h1-p1"/></p>
				<p><FormattedMessage id="h3-h1-p2"/></p>
				<p><FormattedMessage id="h3-h1-p3"/></p>
				<p><FormattedMessage id="h3-h1-p4"/></p>
			</div>

		</div>)
    );
}