// react imports
import React from 'react';
import { Route, Switch } from 'react-router-dom';

// component imports
import HomeContainer from './containers/Home/HomeContainer';
import BasicsContainer from './containers/Basics/BasicsContainer';
import HeartFailureContainer from './containers/Basics/HeartFailure/HeartFailureContainer';
import HeartNoPumpContainer from './containers/Basics/HeartNoPump/HeartNoPumpContainer';
import HeartPumpContainer from './containers/Basics/HeartPump/HeartPumpContainer';
import WhoGetsHFContainer from './containers/Basics/WhoGetsHF/WhoGetsHFContainer';

import MMContainer from './containers/Cases/MMContainer';
import CasesContainer from './containers/Cases/CasesContainer';
import CaseBPContainer from './containers/Cases/CaseBP/CaseBPContainer';
import CaseBP2Container from './containers/Cases/CaseBP/CaseBP2Container';
import CaseSwellingContainer from './containers/Cases/CaseSwelling/CaseSwellingContainer';
import CaseWeightGainContainer from './containers/Cases/CaseWeightGain/CaseWeightGainContainer';
import CaseMedicationContainer from './containers/Cases/CaseMedication/CaseMedicationContainer';
import CaseExerciseContainer from './containers/Cases/CaseExercise/CaseExerciseContainer';
import CaseDietContainer from './containers/Cases/CaseDiet/CaseDietContainer';
import CaseVisitContainer from './containers/Cases/CaseVisit/CaseVisitContainer';

import SymptomsContainer from './containers/Symptoms/SymptomsContainer';
import ChestDiscomfortContainer from './containers/Symptoms/ChestDiscomfort/ChestDiscomfortContainer';
import CommonSymptomsContainer from './containers/Symptoms/CommonSymptoms/CommonSymptomsContainer';
import FatigueContainer from './containers/Symptoms/Fatigue/FatigueContainer';
import LBSwellingContainer from './containers/Symptoms/LBSwelling/LBSwellingContainer';
import ShortnessBreathContainer from './containers/Symptoms/ShortnessBreath/ShortnessBreathContainer';
import WeightGainContainer from './containers/Symptoms/WeightGain/WeightGainContainer';

import EmergContainer from './containers/Emerg/EmergContainer'; 
import EmergFall_injuryContainer from './containers/Emerg/EmergFall_injury/EmergFall_injuryContainer.js';
import EmergChestPainContainer from './containers/Emerg/EmergChestPain/EmergChestPainContainer';
import EmergWsobContainer from './containers/Emerg/EmergWsob/EmergWsobContainer';

import TrafficLandingContainer from './containers/TrafficLight/TrafficLanding/TrafficLandingContainer';
import TrafficToolContainer from './containers/TrafficLight/TrafficTool/TrafficToolContainer';



import Contact from './containers/Contact';
import PrivacyPolicy from './containers/PrivacyPolicy';
import TermsAndConditions from './containers/TermsAndConditions';

import NotFound from './containers/NotFound';


export default function Routes(){
	return (
		<Switch>
			<Route exact path="/">
				<HomeContainer/>
			</Route>

			{/* Basics */}
			<Route exact path="/basics">
				<BasicsContainer/>
			</Route>
			<Route exact path="/basics/heart-failure">
				<HeartFailureContainer/>
			</Route>
			<Route exact path="/basics/heart-pump">
				<HeartPumpContainer/>
			</Route>
			<Route exact path="/basics/heart-no-pump">
				<HeartNoPumpContainer/>
			</Route>
			<Route exact path="/basics/who-hf">
				<WhoGetsHFContainer/>
			</Route>
		{/* Symptoms */}
			<Route exact path="/symptoms">
				<SymptomsContainer/>
			</Route>
			<Route exact path="/symptoms/chest-discomfort">
				<ChestDiscomfortContainer/>
			</Route>
			<Route exact path="/symptoms/common-symptoms">
				<CommonSymptomsContainer/>
			</Route>
			<Route exact path="/symptoms/fatigue">
				<FatigueContainer/>
			</Route>
			<Route exact path="/symptoms/lb-swelling">
				<LBSwellingContainer/>
			</Route>
			<Route exact path="/symptoms/shortness-breath">
				<ShortnessBreathContainer/>
			</Route>
			<Route exact path="/symptoms/weight-gain">
				<WeightGainContainer/>
			</Route>

			<Route exact path="/m_m">
				<MMContainer/>
			</Route>

			{/* Cases */}
			<Route exact path="/cases">
				<CasesContainer/>
			</Route>
			<Route exact path="/cases/blood-pressure">
				<CaseBPContainer/>
			</Route>
			<Route exact path="/cases/blood-pressure-2">
				<CaseBP2Container/>
			</Route>
			<Route exact path="/cases/swelling">
				<CaseSwellingContainer/>
			</Route>
			<Route exact path="/cases/weight-gain">
				<CaseWeightGainContainer/>
			</Route>
			<Route exact path="/cases/medication">
				<CaseMedicationContainer/>
			</Route>
			<Route exact path="/cases/exercise">
				<CaseExerciseContainer/>
			</Route>
			<Route exact path="/cases/diet">
				<CaseDietContainer/>
			</Route>
			<Route exact path="/cases/visit">
				<CaseVisitContainer/>
			</Route>

			{/* Emergencies */}
			<Route exact path="/emerg">
				<EmergContainer/>
			</Route>
			<Route exact path="/emerg/chest-pain">
				<EmergChestPainContainer/>
			</Route>
			<Route exact path="/emerg/fall-injury">
				<EmergFall_injuryContainer/>
			</Route>
			<Route exact path="/emerg/wsob">
				<EmergWsobContainer/>
			</Route>


			<Route exact path="/trafficlight">
				<TrafficLandingContainer/>
			</Route>
			<Route exact path="/traffictool">
				<TrafficToolContainer/>
			</Route>

		    <Route exact path="/contact">
		    	<Contact/>
		    </Route>
		    <Route exact path="/privacy-policy">
		    	<PrivacyPolicy/>
		    </Route>
		    <Route exact path="/terms-and-conditions">
		    	<TermsAndConditions/>
		    </Route>
			<Route>
				<NotFound/>
			</Route>
		</Switch>
	)
}
