import React, {useEffect} from 'react';
import './SurveyQuestion.scss';
import { useAppContext } from "./../../libs/contextLib";
import { FormattedMessage } from 'react-intl'

import { 
	getAnswerFromString, updateAnswerString, answerString2List, answerList2String,
	updateMcString, mcAnswers2ListAndDict, updateSelectString
} from './../../libs/trafficLib';
import { NUM_QUESTIONS_PER_SYMPTOM } from '../../containers/TrafficLight/TrafficTool/SYMPTOMS_QUESTIONS'
import { trafficQuestionTrans } from './../../translations/trafficToolTranslations';

export default function SurveyQuestion(props){
	// const history = useHistory();
	const { 
		trafficLightAnswers, setTrafficLightAnswers, 
		mainSymptom, language, checkedState, setCheckedState
	} = useAppContext();
	const answerList = answerString2List(trafficLightAnswers);

	useEffect(() => {
		if (trafficLightAnswers == null) {
			setTrafficLightAnswers(",".repeat(NUM_QUESTIONS_PER_SYMPTOM[mainSymptom]-1))
		}
		console.log('checkedState',checkedState)
	});

	async function updateInput(e, question_id, question_type, choice_ix=null, is_conditional=false, conditional_key=null){
		console.log("****Inside update input");

		console.log("trafficLightAnswers")
		console.log(trafficLightAnswers);

		const curr_answer = await getAnswerFromString(trafficLightAnswers, question_id)
		console.log("question_id")
		console.log(question_id)

		console.log("curr_answer")
		console.log(curr_answer);

		console.log("question_type")
		console.log(question_type)

		var new_string;
		
		if (question_type == "YESNO" || question_type == "SELECTCHOICE"){
			console.log("inside if: question_type == YESNO/SELECTCHOICE")
			new_string = await updateSelectString(
				curr_answer, e.target.value, is_conditional)
		} else if (question_type == "MULTICHOICE"){
			console.log("inside else if: question_type == MULTICHOICE")
			console.log(e.target.value)
			console.log(e.target.checked)
			console.log('is conditional?',is_conditional)
			updateCheckedState(choice_ix);

			if (is_conditional){
				new_string = await updateMcString(
					curr_answer, // mc_string
					e.target.value, // e_target_id
					e.target.value, // e_target_value
					is_conditional, conditional_key
				)
			} else {
				new_string = await updateMcString(
					curr_answer, // mc_string
					e.target.value, // e_target_id
					e.target.checked, // e_target_value
					is_conditional, conditional_key
				)
			}		
		}
		console.log("new_string")
		console.log(new_string)

		const new_answer_string = updateAnswerString(trafficLightAnswers, question_id, new_string)
		setTrafficLightAnswers(new_answer_string)
		console.log("At end of updateInput")
		console.log(new_answer_string)
		console.log("**********************")
	}

	function updateCheckedState(position){
		const updatedCheckedState = checkedState.map((val, index) => { 
				if (position===index){
					return !val
				} else {
					return val
				}
			}
		);
		console.log('updated state',updatedCheckedState)
		setCheckedState(updatedCheckedState);		
	};

	function shouldSubQuestionShow(question_id, question_type, choice_id){
		const ans_string = getAnswerFromString(trafficLightAnswers, question_id)
		if (question_type == "MULTICHOICE"){
			const [mc_options_list, mc_conditionals_dict] = mcAnswers2ListAndDict(ans_string)
			var unwrapped_list = []
			for (var i=0; i<mc_options_list.length; i++){
				if (Array.isArray(mc_options_list[i])){
					unwrapped_list.push(mc_options_list[i][0])
				} else {
					unwrapped_list.push(mc_options_list[i])
				}
				
			}
			if (unwrapped_list.includes(choice_id)){
				return true
			}
		} else { // YESNO or SELECTCHOICE
			const ans_choice = ans_string.split(":")[0]
			if (ans_choice == choice_id){
				return true
			}
		}
		return false

	}

	return(
		<div className="SurveyQuestion">
			<h4>{props.question.question}</h4>
			{ (props.question.type)==="YESNO" && 
				<div>
					<input type="radio" 
							value="yes"
							checked={answerList[props.ix]==="yes"} 
							onChange={(e) => updateInput(e, props.question.id, props.question.type)} 
							name={props.question.id}/><FormattedMessage id={trafficQuestionTrans[language]["yes"]}/>
					
					<br/>
					{ props.question.has_conditional && 
						shouldSubQuestionShow(props.question.id, props.question.type, "yes") && 
						props.question.conditional_choices.includes("yes") && 
							<div className="SubQuestion">
								<h4>{props.question.conditional_info["yes"].sub_question}</h4>
								{props.question.conditional_info["yes"]['sub_ids'].map((sub_id) => (
									<div>
										<input type="radio" 
												value={sub_id} 
												checked={answerList[props.ix].includes(sub_id)}
												onChange={(e) => updateInput(
													e, props.question.id, 
													props.question.type, "",
													true, // is_conditional
													'yes' // conditional_key
												)} 
												name={props.question.id + "yes"}/> 
											{props.question.conditional_info["yes"]['sub_labels'][sub_id]}
										<br/>
									</div>
								))}
							</div>
					}
					<input type="radio" 
							value="no" 
							checked={answerList[props.ix]==="no"} 
							onChange={(e) => updateInput(e, props.question.id, props.question.type)} 
							name={props.question.id}/><FormattedMessage id={trafficQuestionTrans[language]["no"]}/>
					{ props.question.has_conditional && 
						shouldSubQuestionShow(props.question.id, props.question.type, "no") && 
						props.question.conditional_choices.includes("no") && 
							<div className="SubQuestion">
								<h4>{props.question.conditional_info["no"].sub_question}</h4>
								{props.question.conditional_info["no"]['sub_ids'].map((sub_id) => (
									<div>
										{console.log('inside sub question', sub_id, answerList[props.ix])}
										<input type="radio" 
												value={sub_id}
												checked={answerList[props.ix].includes(sub_id)}
												onChange={(e) => updateInput(
														e, props.question.id, 
														props.question.type, "",
														true, // is_conditional
														'no' // conditional_key
														)}
												name={props.question.id + "no"}/> 
											{props.question.conditional_info["no"]['sub_labels'][sub_id]}
										<br/>
									</div>
								))}
							</div>
					}
				</div>
			}
			{ (props.question.type)==="MULTICHOICE" && 
				<div>
					{ props.question.choice_ids.map((choice_id, choice_ix) => (
					<div>
						<input type="checkbox" 
								value={choice_id}
								checked={checkedState[choice_ix]} 	
								onChange={(e) => updateInput(e, props.question.id, props.question.type, choice_ix)} 
								name={props.question.id}/> {props.question.choice_labels[choice_id]}
						<br/>
						{ props.question.has_conditional && 
							shouldSubQuestionShow(props.question.id, props.question.type, choice_id) && 
							props.question.conditional_choices.includes(choice_id) && 
							<div className="SubQuestion">
								<h4>{props.question.conditional_info[choice_id].sub_question}</h4>
								{props.question.conditional_info[choice_id]['sub_ids'].map((sub_id) => (
									<div>
										<input type="radio" 
												value={sub_id}
												checked={mcAnswers2ListAndDict(answerList[props.ix])[1][choice_id]===sub_id}
												onChange={(e) => updateInput(
													e, props.question.id, 
													props.question.type, "",
													true, // is_conditional
													choice_id // conditional_key
												)}  
												name={props.question.id + choice_id}/> 
											{props.question.conditional_info[choice_id]['sub_labels'][sub_id]}
										<br/>
									</div>
								))}
							</div>
						}
					</div>
					))}	
				</div>
			}
			{ (props.question.type)==="SELECTCHOICE" && 
				<div>
					{ props.question.choice_ids.map((choice_id) => (
					<div>
						<input type="radio" 
								value={choice_id} 
								checked={answerList[props.ix]===choice_id}
								onChange={(e) => updateInput(e, props.question.id, props.question.type)} 
								name={props.question.id}/> {props.question.choice_labels[choice_id]}
						<br/>
						{ props.question.has_conditional && 
						  shouldSubQuestionShow(props.question.id, props.question.type, choice_id) && 
						  props.question.conditional_choices.includes(choice_id) && 
							<div className="SubQuestion">
								<h4>{props.question.conditional_info[choice_id].sub_question}</h4>
								{props.question.conditional_info[choice_id]['sub_ids'].map((sub_id) => (
									<div>
										<input type="radio" 
												value={sub_id}
												checked={answerList[props.ix].includes(sub_id)}
												onChange={(e) => updateInput(
													e, props.question.id, 
													props.question.type, "",
													true, // is_conditional
													choice_id // conditional_key
												)}  
												name={props.question.id + choice_id}/> 
											{props.question.conditional_info[choice_id]['sub_labels'][sub_id]}
										<br/>
									</div>
								))}
							</div>
						}
					</div>
					))}	
				</div>
			}
		</div>
	);

}