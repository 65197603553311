import React from 'react';
import { useAppContext } from "./../../libs/contextLib";
// import { useHistory } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SurveyQuestion from './SurveyQuestion';


export default function SurveyPage(props){
	// const history = useHistory();
	const { language } = useAppContext();
	
	return(
		<div className="SurveyPage">
			{console.log('survey page', props.survey_page.length)}
			{ props.survey_page.map((question, qIx) => (
				<SurveyQuestion question={question} ix={qIx}/> )
			)}
		</div>
	);

}