import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../../libs/contextLib";

import ShortnessBreath from './ShortnessBreath';

import { shortnessbreathTranslations } from '../../../translations/symptomsTranslations'

export default function ShortnessBreathContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={shortnessbreathTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<ShortnessBreath size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<ShortnessBreath size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}