import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../libs/contextLib";

import './Home.scss';
import {homepageTranslations} from '../../translations/homepageTranslations';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import IconCard from '../../components/IconCard';
import 'bootstrap/dist/css/bootstrap.min.css'

export default function Home(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		window.scrollTo(0, 0)
	}, []);
	

	const history = useHistory();
	const class_name = props.size;
	// const { language } = useAppContext();
	// const [cookies1, setCookie1,] = useCookies(['lang']);
	const [cookies2, setCookie2,] = useCookies(['f1_lang']);


	return (
		<div className={"ContentPage Home "+class_name}>
			{typeof cookies2.f1_lang !== 'undefined' && cookies2.f1_lang === 'es' ? (
          		<div className="CardContainer">
				  <div class="row">
					  <IconCard last="false" name= {homepageTranslations['es']['basics']} redirect_url="/basics" img = "icons/basic_icon.png"/>
					  <IconCard last="false" name={homepageTranslations['es']['symptoms']} redirect_url="/symptoms" img = "icons/symptoms_icon.png"/>
				  </div>
				  <div class="row">
					  <IconCard last="false" name={homepageTranslations['es']['m_m']} redirect_url="/m_m" img = "icons/cases_example_icon.png"/>
					  <IconCard last="true" name={homepageTranslations['es']['traffic']} redirect_url="/trafficlight" img = "icons/traffic_light_icon.png"/>
				  </div>
			  </div>
        	) : (
				<div className="CardContainer">
					<div class="row">
						<IconCard last="false" name= {homepageTranslations['en']['basics']} redirect_url="/basics" img = "icons/basic_icon.png"/>
						<IconCard last="false" name={homepageTranslations['en']['symptoms']} redirect_url="/symptoms" img = "icons/symptoms_icon.png"/>
					</div>
					<div class="row">
						<IconCard last="false" name={homepageTranslations['en']['m_m']} redirect_url="/m_m" img = "icons/cases_example_icon.png"/>
						<IconCard last="true" name={homepageTranslations['en']['traffic']} redirect_url="/trafficlight" img = "icons/traffic_light_icon.png"/>
					</div>
				</div>
        	)}
		</div>
    );
}