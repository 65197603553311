import { useState, useEffect, useRef } from 'react';

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function(event) {
      // console.log("event target id is")
      // console.log(event.target.id)
      // console.log("event target value is")
      // console.log(event.target.value)
      setValues({
        ...fields,
        [event.target.id]: event.target.value
      });

      // console.log("use form fields is called, new values are: ")
      // console.log(fields)
    }
  ];
}

