import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';

// Hooks
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';
import { useAppContext } from "../libs/contextLib";

// style & UI components
import './CustomNavBar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import {homepageTranslations} from '../translations/homepageTranslations';

// style & UI components
import ReactTooltip from 'react-tooltip';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Form, FormInput } from "shards-react";


export default function CustomNav(props) {
  // Other setup
  const history = useHistory();
  const [, setCookie, ] = useCookies(['f1_lang']);
  const [cookies1, , removeCookie1] = useCookies(['uid']);

  // hooks
  const { 
    trackingIssue,setTrackingIssue,
    username,
    setShowAuthModal,
    language, setLanguage
  } = useAppContext();
    

  // Icon click
  const handleUserIconClick = () => {
    setShowAuthModal(true);
  };

  const languageToENG = () => {
    setLanguage("en");
    setCookie('f1_lang', "en", {path: "/"});
  }
  const languageToESP = () => { 
    setLanguage("es");
    setCookie('f1_lang', "es", {path: "/"});
  }
  function redirect(){
    /*
		if (typeof cookies1.uid !== 'undefined'){
			ReactGA.set({ userId: cookies1.uid });
		}*/
		history.push('/')
	}

  return (
    <div className="MonchNavBar">
      <div className = { props.size }>
        <div id = "monch-nav-search" className = "monch-nav-section">
          <span>
             <span className={language=="es" ? "language-toggle" : "language-toggle selected"} onClick={languageToENG}>EN</span> 
             &nbsp;|&nbsp;
             <span className={language=="es" ? "language-toggle selected" : "language-toggle"} onClick={languageToESP}>ES</span>
          </span>

        </div>
        <div id = "monch-nav-options" className = "monch-nav-section">
          <i className={username === "" || typeof username == 'undefined' ? "icon-alert" : ""}>
            <FontAwesomeIcon icon="user" size="1x" onClick={handleUserIconClick}/>
          </i>
        </div>
        <div id = "monch-nav-logo" className = "monch-nav-section">
          <span onClick={redirect}>Heart Care eCourse</span>
        </div>  
      </div>
    </div>
  );

}


