import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../libs/contextLib";

import './Cases.scss';
import {casesTranslations} from '../../translations/casesTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import ContentCard from '../../components/ContentCard';
import PageTitle from '../../components/PageTitle';

export default function Cases(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		setIsLoading(false);
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);


	return (
		!isLoading && (
			<div className={"ContentPage Cases "+screen_size}>
				<PageTitle 
					title={casesTranslations[language]['title']}
					title_helptext={casesTranslations[language]['title_helptext']}
				/>
				<br/><br/>
			<div className="CardContainer">
				<ContentCard 
					name={casesTranslations[language]['bp']}
					redirect_url="/cases/blood-pressure"
				/>
				<ContentCard 
					name={casesTranslations[language]['weightgain']}
					redirect_url="/cases/weight-gain"
				/>
				<ContentCard 
					name={casesTranslations[language]['medication']}
					redirect_url="/cases/medication"
				/>
				<ContentCard 
					name={casesTranslations[language]['exercise']}
					redirect_url="/cases/exercise"
				/>
				<ContentCard 
					name={casesTranslations[language]['diet']}
					redirect_url="/cases/diet"
				/>
				<ContentCard 
					name={casesTranslations[language]['visit']}
					redirect_url="/cases/visit"
				/>
			</div>

		</div>
		)
    );
}