import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../libs/contextLib";

import Symptoms from './Symptoms';

import { symptomsTranslations } from '../../translations/symptomsTranslations'

export default function SymptomsContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={symptomsTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<Symptoms size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<Symptoms size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}