const basicsTranslations = {
	'en': {
		"title": "The Basics of Heart Failure",
		"title_helptext": "Learn about heart failure",
		"heartfailure": "What is Heart Failure?",
		"heartpump": "What happens when the heart pumps well?",
		"heartnopump": "What happens when the heart does not pump well?",
		"whogetshf": "Who gets Heart Failure?",
	},
	'es': {
		"title": "La información básica de la insuficiencia cardíaca",
		"title_helptext": "Aprenda sobre la insuficiencia cardíaca ",
		"heartfailure": "¿Qué es la insuficiencia cardíaca?",
		"heartpump": "¿Qué ocurre cuando el corazón bombea bien?",
		"heartnopump": "¿Qué ocurre cuando el corazón no bombea bien? ",
		"whogetshf": "¿A quién le da insuficiencia cardíaca?",
	}
}

const heartfailureTranslations = {
'en': {        "title": "Heart Failure Basics", 
				"h1": "What is Heart Failure?", 
			   "h1p1": "Heart Failure is a condition where the heart does not pump blood to the rest of the body well. This can interfere with the body receiving the oxygen and nutrients it needs.", 
			   "h1p2": "Heart Failure can develop suddenly (for example, after a heart attack) or slowly over time (due to things like high blood pressure).",
				"h1p3": "To learn more check out this ", 
				"h1p4": "PALS article"
			},

				
'es': {        "title": "La información básica de la insuficiencia cardíaca", 
				"h1": "¿Qué es la insuficiencia cardíaca?", 
			   "h1p1": "La insuficiencia cardíaca es una condición en la que el corazón no bombea bien la sangre al resto del cuerpo. Esto puede interferir con que el cuerpo reciba el oxígeno y los nutrientes que necesita.", 
			   "h1p2": "La insuficiencia cardíaca se puede desarrollar repentinamente (por ejemplo, después de un ataque de corazón), o lentamente a través del tiempo (debido a factores como presión arterial alta).",
				"h1p3": "Para obtener más información sobre esto ",
				"h1p4": "artículo PALS" 
			},
}
const heartnopumpTranslations = {
	'en': { "title": "Heart Failure Basics",
	"h1": "What happens when the heart does not pump well?",
	"h1p1": "When the left side of the heart does not pump well, fluid builds up in lungs and makes the patient short of breath.",
	"h1p2": "When the right side of the heart does not pump well, fluid builds up in the body, leading to a swollen abdomen and legs.",
	},
	'es':{ "title": "La información básica de la insuficiencia cardíaca",
	"h1": "¿Qué ocurre cuando el corazón no bombea bien? ",
	"h1p1": "Cuando el lado izquierdo del corazón no bombea bien, se acumula líquido en los pulmones y hace que el paciente tenga dificultad para respirar.",
	"h1p2": "Cuando el lado derecho del corazón no bombea bien, se acumula líquido en el cuerpo, causando hinchazón en el abdomen y en las piernas.",
	}
}

const heartpumpTranslations = {
	'en': { "title": "Heart Failure Basics",
	"h1": "What happens when the heart pumps well?",
	"h1p1": "The heart is a muscle that pumps blood to the body.", 
			"h1p2": "Oxygen is pumped through the right side of the heart to the lungs.", 
				"h1p3": "In the lungs, the blood receives oxygen.",
				"h1p4": "Oxygen rich blood moves through the left side of the heart, passing to all other parts of the body.", 

	}, 
	'es': { "title": "La información básica de la insuficiencia cardíaca",
	"h1": "¿Qué ocurre cuando el corazón bombea bien?",
	"h1p1": "El corazón es un músculo que bombea la sangre al cuerpo.", 
			"h1p2": "El oxígeno se bombea a través del lado derecho del corazón a los pulmones. ", 
				"h1p3": "En los pulmones, la sangre recibe oxígeno.",
				"h1p4": "La sangre rica en oxígeno se mueve hacia el lado izquierdo del corazón, pasando a todas las demás partes del cuerpo.", 

	}
}
const whogetsHFTranslations = { 'en': { "title": "Heart Failure Basics",
	"h1": "Who gets Heart Failure?",
	"h1p1": "Patients who have cardiac history and those with a history of heart muscle damage get heart failure",
	"h1p2":	"As people age and have other cardiovascular risk factors, they are more likely to get heart failure",
}, 
	'es': { "title": "La información básica de la insuficiencia cardíaca",
	"h1": "¿A quién le da insuficiencia cardíaca?",
	"h1p1": "A los pacientes con antecedentes cardíacos y aquellos con historial de daño del músculo del corazón les da insuficiencia cardíaca",
	"h1p2":	"A medida que la gente envejece y causa de otros factores de riesgo cardiovasculares, tienen más probabilidades de que les dé insuficiencia cardíaca",
}

	}
export { basicsTranslations, heartfailureTranslations, heartnopumpTranslations, heartpumpTranslations, whogetsHFTranslations  };