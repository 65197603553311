const emergTranslations = {
	'en': {
		"title": "Handling Worsening Symptoms in Heart Failure",
		"title_helptext": "Handling heart failure emergencies for your patients",
		"wsob": "Shortness of Breath", 
		"chest_pain": "Chest Pain",
		"fall_injury": "Falls and Injuries"
	},
	'es': {
		"title": "Manejo de síntomas que empeoran en la insuficiencia cardíaca ",
		"title_helptext": "Manejo de emergencias de insuficiencia cardíaca para sus pacientes ",
		"wsob": "Falta de aliento", 
		"chest_pain": "Dolor de pecho",
		"fall_injury": "Caídas y lesiones"
	}
}

const emergWsobTrans = {
	'en': {
		"title": "Shortness of Breath",
		"overview": "Mrs. S has shortness of breath after 5 steps of walking and leg swelling to her knees. She has a runny nose, possible catching a cold. She takes medications regularly.",
		"h1": "What are the concerning symptoms?",
		"h1p1": "Shortness of breath, which could be a sign of infection or worsening heart failure",
		"h1p2": "Decreased exercise tolerance",
		"h1p3": "Swelling up to her knees, indicating more fluid",
		"h1p4": "Possible upper respiratory infection",
		"h2": "What would you do?",
		"h2p1": "Take vital signs (blood pressure, oxygen level, temperature, heart rate)",
		"h2p2": "Assess for other symptoms",
		"h2p3": "Assess breathing at rest",
		"h3-h1": "Her heart rate is now 110, blood pressure is 100/60, temperature is 102F, and her O2 level is 90%.",
		'h3-h1-q': "What does this mean?",
		"h3-h1p1":"This means that her heart rate is high and blood pressure is low. She also has a fever. Her oxygen level is low.",
		"h3-h1p2":"This is either infection or worsening heart failure",
		"h3-h2":"What to do next?",
		"h3-h2p1":"Go to emergency department or call 911"
	},
	'es':{
		"title": "Falta de aliento",
		"overview": "La Sra. S tiene falta de aliento después de dar 5 pasos e hinchazón de piernas hasta las rodillas. Tiene catarro, posiblemente le esté dando un resfriado. Toma los medicamentos con regularidad.",
		"h1": "¿Cuáles son los síntomas preocupantes?",
		"h1p1": "Falta de aliento, lo cual podría ser una señal de infección o empeoramiento de la insuficiencia cardíaca ",
		"h1p2": "Disminución de tolerancia al ejercicio",
		"h1p3": "Hinchazón hasta las rodillas, lo cual indica más líquido ",
		"h1p4": "Posible infección de las vías respiratorias superiores",
		"h2": "¿Qué debe hacer?",
		"h2p1": "Tomar los signos vitales (presión arterial, nivel de oxígeno, temperatura, ritmo cardíaco)",
		"h2p2": "Evaluar otros síntomas",
		"h2p3": "Evaluar la respiración en reposo",
		"h3-h1": "Su ritmo cardíaco es ahora 110, la presión arterial es 100/60, la temperatura es 102 F, y su nivel de oxígeno es 90%.",
		'h3-h1-q': "¿Qué significa esto?",
		"h3-h1p1":"Significa que el ritmo cardíaco está elevado y la presión arterial es baja. También tiene fiebre. Su nivel de oxígeno es bajo.",
		"h3-h1p2":"Esto es una infección o un empeoramiento de la insuficiencia cardíaca.",
		"h3-h2":"¿Qué debe hacer a continuación?",
		"h3-h2p1":"Acuda a la sala de emergencias o llame al 911"
	}
}

const emergFiTrans = {
	'en': {
		"title": "Falls and Injuries",
		"overview": "Mrs. W had a fall last night. Since then she hasn't been as quick or responsive to your questions as before. She also had difficulty walking, favoring one side of body over another. ",
		"h1": "What are the concerning symptoms?",
		"h1p1": "Possibility of injury, especially head injury",
		"h1p2": "Possibility of stroke",
		"h1p2-sub": "Common stroke signs: trouble walking, slurred speech, asymmetrical movements, disorientation",
		"h1p3": "Poor walking/Imbalance",
		"h1p3-sub": "Reason: Stroke, broken bones or blood loss",
		"h2": "What would you do?",
		"h2p1": "Look for bruising or cuts",
		"h2p2": "Check for any head bumps/cuts or signs of a head injury",
		"h2p3": "Check for speech, balance, orientation (does she know where she is, day, place, who you are)",
		"h2p4": "Check vitals (is her blood pressure too low?)",
		"h2p5": "Confirm whether she has taken too much of her medications or started a new medication?",
		"h3": "Mrs. W starts speaking unclearly and then becomes unconscious. Her pulse is weak and irregular.",
		"h3-h1": "Call 911",
		"h3-h2": "What would you do?",
		"h3-h2-p1":"Stay calm",
		"h3-h2-p2":"Get help from anyone else in the house",
		"h3-h2-p3":"911 operator will give you all the instructions you need. (i.e., if you can feel a pulse at the neck, perform chest compressions)"
	},
	'es':  {
		"title": "Caídas y lesiones",
		"overview": "La Sra. W se cayó anoche. Desde entonces no responde con rapidez a sus preguntas como lo hacía antes. También tenía dificultad para caminar, favoreciendo un lado del cuerpo frente al otro. ",
		"h1": "¿Cuáles son los síntomas preocupantes? ",
		"h1p1": "Posibilidad de lesión, especialmente lesión de la cabeza ",
		"h1p2": "Posibilidad de embolia",
		"h1p2-sub": "Señales comunes de embolia: dificultad para caminar, problemas al hablar, movimientos asimétricos, desorientación",
		"h1p3": "Caminar mal/desequilibrio",
		"h1p3-sub": "Razón: Embolia, fractura de huesos o pérdida de sangre ",
		"h2": "¿Qué debe hacer?",
		"h2p1": "Busque si tiene contusiones o cortadas",
		"h2p2": "Verifique si tiene chipotes/cortadas en la cabeza, o señales de lesión de la cabeza",
		"h2p3": "Verifique el habla, el equilibrio, orientación (sabe dónde está, qué día es, el lugar, quién es usted)",
		"h2p4": "Verifique los signos vitales (¿tiene baja presión arterial?)",
		"h2p5": "Confirme si ha tomado exceso de sus medicamentos o ¿empezó a tomar un medicamento nuevo?",
		"h3": "La Sra. W empieza a hablar confusamente y luego pierde el conocimiento. Su pulso es débil e irregular.",
		"h3-h1": "Llame al 911",
		"h3-h2": "¿Qué debe hacer?",
		"h3-h2-p1":"Mantengan la calma",
		"h3-h2-p2":"Obtenga ayuda de alguna persona en la casa",
		"h3-h2-p3":"El operador del 911 le dará todas las instrucciones que necesite. (Es decir, si puede sentir el pulso en el cuello, hacer compresiones en el pecho)"
	}
}
const emergChestPainTrans = {
	'en': {
		"title": "Chest Pain",
		"overview": "Mr. C is having chest pain while sitting on a couch. He visited a cardiologist a week ago and was given new medications. It helped for a few days but symptoms worsened.",
		"h1": "What are the concerning symptoms?",
		"h1p1": "Chest pain at rest is very worrisome",
		"h1p2": "Worsening chest pain may indicate a heart attack",
		"h2": "What would you do?",
		"h2p1": "Ask and observe",
		"h2p1-sub":"Looks uncomfortable? Severe pain? Difficulty breathing? Unable to walk?",
		"h2p2": "Check his vitals",
		"h2p3": "Confirm whether he has been taking the new medications",
		"h3": "New info: Mr. C’s heart rate is 110, blood pressure is 182/110, temperature 99F, and respiration rate is 22.",
		"h3-h1": "What does this mean?",
		"h3p1": "Blood pressure, heart rate, and breathing are elevated.",
		"h3-h2": "What would you do?",
		"h3p2-sub":"Call the doctor or go to the emergency department."
	},
	'es': {
		"title": "dolor de pecho ",
		"overview": "El Sr. C tiene dolor de pecho mientras está sentado en un sofá. Tuvo una cita con un cardiólogo hace una semana y le dieron nuevos medicamentos. Ayudaron unos días pero los síntomas empeoraron.",
		"h1": "¿Cuáles son los síntomas preocupantes?",
		"h1p1": "El dolor de pecho en reposo es muy preocupante",
		"h1p2": "El empeoramiento del dolor de pecho puede indicar un ataque de corazón",
		"h2": "¿Qué debe hacer?",
		"h2p1": "Pregunte y observe",
		"h2p1-sub":"¿Parece incómodo? ¿Dolor severo? ¿Dificultad para respirar? ¿No puede caminar? ",
		"h2p2": "Revise sus signos vitales",
		"h2p3": "Confirme si ha estado tomando nuevos medicamentos El empeoramiento del dolor de pecho puede indicar un ataque de corazón",
		"h3": "Nueva información: El ritmo cardíaco del Sr. C es 110, la presión arterial es 182/110, la temperatura 99 F y la frecuencia respiratoria es 22.",
		"h3-h1": "¿Qué significa esto?",
		"h3p1": "La presión arterial, el ritmo cardíaco y la respiración están elevados",
		"h3-h2": "¿Qué debe hacer?",
		"h3p2-sub":"lame a médico o acuda a la sala de emergencias."
	},
}
export { 
	emergTranslations,emergWsobTrans,emergFiTrans,emergChestPainTrans};