const contactTranslations = {
	'en': {
		contact_us: "CONTACT US",

	},
	'es': {
		contact_us: "Contacta con nosotros",
	}
}

export { contactTranslations };