import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useAppContext } from "./../../../libs/contextLib";

import './TrafficLanding.scss';
import {trafficLandingTranslations} from './../../../translations/trafficLandingTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import PageTitle from '../../../components/PageTitle';
import ScenarioBox from '../../../components/ScenarioBox';

export default function TrafficLanding(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			// ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language);
		setIsLoading(false);
		window.scrollTo(0, 0)
		resetContexts();
	}, []);
	
	const history = useHistory();
	const class_name = props.size; // this is for screen size only
	const { language, setMainSymptom, mainSymptom, checkedState, setCheckedState } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	function resetContexts(){
		if (mainSymptom!=null){
			setMainSymptom(null)
		}
		if (checkedState!=null){
			setCheckedState(null)
		}
	}

	return (
		!isLoading && (
		<div className={"ContentPage TrafficLanding "+class_name}>
			<div>
				<PageTitle 
					title={trafficLandingTranslations[language]['title']}
				/>
				
				<button onClick={() => history.push("/traffictool")}>
					{trafficLandingTranslations[language]['goto_tool']}
				</button>

				<ScenarioBox text={trafficLandingTranslations[language]['overview']}/>
				<br></br>
				<div className={"content"}>
					<h3><FormattedMessage id="how_to_title"/></h3>
					<p><FormattedMessage id="how_to_text"/></p>
					<br></br>
			
					<p><FormattedMessage id="review_text1"/></p>
					{ language === "es" ? 
						<img src = '/traffic_light_img/TLA_green_zone_s.png' className = "TrafficLanding_img "></img>:
						<img src = '/traffic_light_img/TLA_green-zone.png' className = "TrafficLanding_img "></img>
					}
					<br></br><br></br>
					<p><FormattedMessage id="review_text2"/></p>
					{ language === "es" ? 
						<img src = '/traffic_light_img/TLA_yellow_zone_s.png' className = "TrafficLanding_img "></img>:
						<img src = '/traffic_light_img/TLA_yellow-zone.png' className = "TrafficLanding_img "></img>
					}
					<br></br><br></br>
					<p><FormattedMessage id="review_text3"/></p>
					{ language === "es" ? 
						<img src = '/traffic_light_img/TLA_red_zone_s.png' className = "TrafficLanding_img "></img>:
						<img src = '/traffic_light_img/TLA_red-zone.png' className = "TrafficLanding_img "></img>
					}	
				</div>
				<br></br>
				<button onClick={() => history.push("/traffictool")}>
					{trafficLandingTranslations[language]['goto_tool']}
				</button>
			</div>
		
		</div>
    ))
}