const symptomsTranslations = {
	'en': {
		"title": "Symptoms in Heart Failure",
		"title_helptext": "Learn about heart failure symptoms",
		"commonsymptoms": "Common Symptoms",
		"chestdiscomfort": "Chest Discomfort",
		"fatigue": "Fatigue",
		"lbswelling": "Leg Swelling and Weight Gain",
		"shortnessbreath": "Shortness of Breath",
		"weightgain": "Weight Gain", 
	},
	'es': {
		"title": "Síntomas de la insuficiencia cardíaca",
		"title_helptext": "Aprenda acerca de los síntomas de la insuficiencia cardíaca",
		"commonsymptoms": "Síntomas comunes",
		"chestdiscomfort": "Molestia en el pecho",
		"fatigue": "Fatiga",
		"lbswelling": "Hinchazón de piernas y aumento de peso ",
		"shortnessbreath": "Falta de aliento",
		"weightgain": "aumento de peso",  
	}
}


const shortnessbreathTranslations = { 'en': { "title": "Heart Failure Basics",
	"h1": "Shortness of Breath",
	"h1p1": "Shortness of breath is common in heart failure. ",
	"h1p2":	"This may occur when patients are walking or when they are resting.",
	"h1p3": "It can be due to the heart not working well, or other things not related to the heart.",
	"h1p4": "It is important to monitor their breathing and discuss with a nurse or doctor if it worsens.",
}, 
	'es': { "title": "La información básica de la insuficiencia cardíaca",
	"h1": "Falta de aliento",
	"h1p1": "La falta de aliento es común en la insuficiencia cardíaca. ",
	"h1p2":	"Esto puede ocurrir cuando los pacientes están caminando o cuando están en reposo.",
	"h1p3": "Puede ser debido a que el corazón no está trabajando bien, o por otras cosas no relacionadas con el corazón.",
	"h1p4": "Es importante vigilar la respiración y hablar con un enfermero o médico si empeora.",
}
}
const chestdiscomfortTranslations = {
'en': { "title": "Heart Failure Basics", 
				"h1": "Chest Discomfort", 
			   "h1p1": "Some patients with heart failure and heart disease may experience chest discomfort.", 
			   "h1p2": "This can occur if there is injury to the heart muscle or if it is working too hard.",
				"h1p3": "If they experience new pain, reach out to the nurse or their doctor for advice. This may be an emergency.", 
			},

				
'es': {        "title": "La información básica de la insuficiencia cardíaca", 
				"h1": "Molestia en el pecho", 
			   "h1p1": "Algunos pacientes con insuficiencia cardíaca y con enfermedad del corazón pueden tener molestia en el pecho.", 
			   "h1p2": "Esto puede ocurrir si hay lesión al músculo del corazón o si está trabajando demasiado duro.",
				"h1p3": "Si surge un nuevo dolor, comuníquese con el enfermero o con su médico para obtener consejo. Esto puede ser una emergencia.", 
			
			}
}
const commonsymptomsTranslations = {
	'en': { "title": "Heart Failure Basics",
	"h1": "Common Symptoms",
	"h1p1": "Heart failure patients often experience symptoms like shortness of breath, fatigue, leg and body swelling and chest discomfort.",
	},
	'es':{ "title": "La información básica de la insuficiencia cardíaca",
	"h1": "Síntomas comunes",
	"h1p1": "Los pacientes con insuficiencia cardíaca a menudo tienen síntomas como falta de aliento, fatiga, hinchazón de piernas y cuerpo y molestia en el pecho.",
	}
}

const fatigueTranslations = {
	'en': { "title": "Heart Failure Basics",
	"h1": "Fatigue",
	"h1p1": "Feeling tired can happen when the heart does not pump oxygen well to the body.", 
			"h1p2": "Getting rest, eating well, taking medications, and seeing the doctor can help improve fatigue.", 
				"h1p3": "It is important to monitor patients energy level",

	}, 
	'es': { "title": "La información básica de la insuficiencia cardíaca",
	"h1": "Fatiga",
	"h1p1": "Puede sentirse cansado cuando el corazón no bombea bien el oxígeno al cuerpo.", 
			"h1p2": "Descansar, comer bien, tomar los medicamentos y ver al médico puede ayudar a mejorar la fatiga.", 
				"h1p3": "Es importante vigilar el nivel de energía de los pacientes",

	}
}

const lbswellingTranslations = { 'en': { "title": "Heart Failure Basics",
	"h1": "Leg Swelling and Weight Gain",
	"h1p1": "Patients with heart failure often retain fluid in their legs and body.",
	"h1p2":	"It is important to check them for swelling on a daily basis.",
	"h1p3": "Elevating their legs and taking their medications may help with swelling.",
	"h1p4": "Patients with heart failure may gain weight because they are retaining fluid.",
	"h1p5":	"It is important for patients with Heart Failure to weigh themselves daily. You can help them with this in the home.",
	"h1p6": "An increase of 2-3 pounds in one day or 5 pounds in a week is concerning. This can be a sign of too much fluid and worsening heart failure."
}, 
	'es': { "title": "La información básica de la insuficiencia cardíaca",
	"h1": "Hinchazón de piernas y aumento de peso ",
	"h1p1": "Los pacientes con insuficiencia cardíaca a menudo retienen líquido en las piernas y el cuerpo. ",
	"h1p2":	"Es importante revisar diariamente si hay hinchazón.",
	"h1p3": "Elevar las piernas y tomar los medicamentos puede ayudar con la hinchazón. ",
	"h1p4": "Los pacientes con insuficiencia cardíaca pueden aumentar de peso porque retienen líquido.",
	"h1p5":	"Es importante que los pacientes con insuficiencia cardíaca se pesen diariamente. Puede ayudarlos con esto en casa.",
	"h1p6": "El aumento de 2 a 3 libras en un día o 5 libras en una semana es preocupante. Puede ser una señal de acumulación de demasiado líquido y empeoramiento de la insuficiencia cardíaca."
}
}

const weightgainTranslations = { 'en': { "title": "Heart Failure Basics",
	"h1": "Weight Gain",
	"h1p1": "Patients with heart failure may gain weight because they are retaining fluid.",
	"h1p2":	"It is important for patients with Heart Failure to weigh themselves daily. You can help them with this in the home.",
	"h1p3": "An increase of 2-3 pounds in one day or 5 pounds in a week is concerning. This can be a sign of too much fluid and worsening heart failure."
}, 
	'es': { "title": "Heart Failure Basics",
	"h1": "Weight Gain",
	"h1p1": "Patients with heart failure may gain weight because they are retaining fluid.",
	"h1p2":	"It is important for patients with Heart Failure to weigh themselves daily. You can help them with this in the home.",
	"h1p3": "An increase of 2-3 pounds in one day or 5 pounds in a week is concerning. This can be a sign of too much fluid and worsening heart failure."
}

	}
export { symptomsTranslations, chestdiscomfortTranslations, commonsymptomsTranslations, fatigueTranslations, 
	lbswellingTranslations, shortnessbreathTranslations, weightgainTranslations};



