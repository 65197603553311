import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../../libs/contextLib";

import CommonSymptoms from './CommonSymptoms';

import { commonsymptomsTranslations } from '../../../translations/symptomsTranslations'

export default function CommonSymptomsContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={commonsymptomsTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<CommonSymptoms size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<CommonSymptoms size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}