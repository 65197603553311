const trafficToolTranslations = {
	'en': {
		"title": "Traffic Light Tool",
		"subtitle_qs": "Please respond to the following questions:",
		"subtitle_results": "Results",
		"next": "Next",
		"back": "Back",
		"submit": "Submit",
		"exit": "Exit"
	},
	'es': {
		"title": "Herramienta de luces del semáforo",
		"subtitle_qs": "Responda las siguientes preguntas:",
		"subtitle_results": "Resultados",
		"next": "Siguiente",
		"back": "Regresar",
		"submit": "Enviar",
		"exit": "Salir"
	}
}

const trafficQuestionTrans = {
	'es': {
		"yes": "Sí",
		"no": "No o no está seguro",
		"first_q": "¿Qué síntoma le preocupa más?*", 
		"sob_q0":"¿Es la falta de aliento un síntoma nuevo?*",
		"sob_q1":"¿Alguno de estos síntomas también ocurre con la falta de aliento?*",
		"sob_q1_sub": "¿El dolor de pecho es severo?",
		"severe": "Severo", 
		"not_severe": "No severo",
		"sob_q2":"¿La falta de aliento empeora al estar en reposo (es decir, cuando el paciente está sentado)?*",
		"sob_q3":"¿La falta de aliento es peor con la actividad (es decir, al caminar, o hacer ejercicio)?",
		"chest_q0":"¿Es la molestia del pecho un nuevo síntoma?*",
		"chest_q1":"¿Está ocurriendo también alguno de los síntomas siguientes?",
		"chest_q2":"¿La molestia del pecho ocurre mientras está activo (como caminando, haciendo ejercicio) o en reposo (mientras el paciente está sentado)?",
		"active":"Mientras está activo",
		"rest":"En reposo",
		"fatigue_q0":"Además de la fatiga (o cansancio), ¿tiene el paciente alguno de estos otros síntomas?*",
		"fatigue_q1":"¿Es la fatiga peor de lo habitual?*",
		"fatigue_q2":"¿Ha estado enfermo el paciente recientemente?",
		"fatigue_q3":"¿Está tomando el paciente nuevos medicamentos?",
		"weight_q0":"¿Cuánto peso ha subido su paciente?*",
		"day_gain":"Aumento de peso de 2 a 3 libras en un día",
		"week_gain":"Aumento de peso de 5 libras en una semana",
		"other":"Otro o No está seguro",
		"weight_q1":"¿Ha estado tomando diuréticos el paciente?",
		"weight_q2":"¿Tiene el paciente alguno de los siguientes síntomas adicionales?",
		"confusion_q0":"¿Es la confusión un nuevo síntoma?*",
		"confusion_q1":"¿Se cayó el paciente o se lesionó la cabeza en los últimos 2 días?*",
		"confusion_q2":"¿Tiene el paciente alguno de los siguientes síntomas adicionales?",
		"confusion_q3":"¿Hay algún cambio en los medicamentos del paciente o la forma en que se los toma?",
		"leg_q0":"¿Es la hinchazón de piernas un nuevo síntoma?*",
		"leg_q1":"¿Es la hinchazón de piernas peor que los días anteriores (por ejemplo, ahora la hinchazón es más arriba)?*",
		"leg_q2":"¿Ha estado tomando el paciente sus diuréticos (como lasix)?",
		"leg_q3":"¿Es la hinchazón en una o ambas piernas?",
		"leg_q4":"¿Tuvo el paciente algún cambio reciente en su dieta (como más sal)? ",
		"one":"Una pierna",
		"both":"Ambas piernas",
		"light_q0":"¿Es el mareo (o sensación de desmayo) un nuevo síntoma?*",
		"light_q1":"¿Se cayó el paciente hoy?",
		"light_q2":"¿Tiene el paciente la presión alta o baja?",
	},
	'en': {
		"yes": "Yes",
		"no": "No or Unsure",
		"first_q": "Which symptom are you most concerned about?*", 
		"sob_q0":"Is shortness of breath a new symptom?*",
		"sob_q1":"Are any of these symptoms also occuring with the shortness of breath?*",
		"sob_q1_sub": "Is the chest pain severe?",
		"severe": "Severe", 
		"not_severe": "Not Severe",
		"sob_q2":"Is shortness of breath worse at rest (e.g. while the patient is just sitting)?*",
		"sob_q3":"Is shortness of breath worse with activity (e.g. walking around, exercising)?",
		"chest_q0":"Is chest discomfort a new symptom?*",
		"chest_q1":"Are any of the below symptoms also occurring?*",
		"chest_q2":"Is chest discomfort occurring while being active (e.g. walking around, exercising) or at rest (e.g. while the patient is just sitting)?",
		"active":"While being active",
		"rest":"At rest",
		"fatigue_q0":"In addition to fatigue (or tiredness), is the patient having any of these other symptoms?*",
		"fatigue_q1":"Is the fatigue worse than usual?*",
		"fatigue_q2":"Has the patient been sick recently?",
		"fatigue_q3":"Is the patient taking new medications?",
		"weight_q0":"How much weight has your client gained?*",
		"day_gain":"Weight gain of 2-3 lbs in a day",
		"week_gain":"Weight gain of over 5 lbs in a week",
		"other":"Other or Unsure",
		"weight_q1":"Has the patient been taking their water pills (i.e. diuretic pills)?",
		"weight_q2":"Does the patient have any of the following additional symptoms?",
		"confusion_q0":"Is the confusion a new symptom?*",
		"confusion_q1":"Did the patient have any falls or head injuries in the last 2 days?*",
		"confusion_q2":"Does the patient have any of the following additional symptoms?*",
		"confusion_q3":"Are there any changes in the patient’s medications or how they are taking them?",
		"leg_q0":"Is the leg swelling a new symptom?*",
		"leg_q1":"Is the leg swelling worse than previous days (e.g. goes up higher now)?*",
		"leg_q2":"Has the patient been taking their water pills (i.e. lasix)?",
		"leg_q3":"Is the swelling in one leg or both legs?",
		"leg_q4":"Did the patient have any recent diet changes (e.g. more salt)?",
		"one":"One leg",
		"both":"Both legs",
		"light_q0":"Is lightheadedness (or feeling faint) a new symptom?*",
		"light_q1":"Did the patient fall today?*",
		"light_q2":"Does the patient have either high or low blood pressure?*",

	}
}

const trafficSymptomsTrans = {
	'en': {
		"sob": "Shortness of breath", 
		"chest_discomfort": "Chest discomfort", 
		"fatigue": "Fatigue", 
		"weight_change": "Weight gain", 
		"confusion": "Confusion", 
		"leg_swelling": "Leg swelling",
		"lightheaded_falls": "Lightheadedness and falls",
		"cough": "Cough", 
		"chest_pain": "Chest pain", 
		"fever": "Fever", 
		"mucus": "Mucus", 
		"wheeze": "Wheeze",
		"shooting_pain": "Shooting pain down arm or pain on left side of chest", 
		"heart_racing": "Heart racing", 
		"sweating": "Sweating", 
		"nausea": "Nausea or vomiting", 
		"regurg": "Regurgitation",
		"lightheaded": "Lightheadedness", 
		"poor_sleep": "Poor sleep and snoring", 
		"low_mood": "Low mood",
		"bowel_changes": "Changes in bowel movements",
		"unconscious": "Loss of consciousness", 
		"slurred_speech": "New slurred speech", 
		"face_asym": "New facial asymmetry", 
		"weakness": "Worsening weakness", 
		"urination": "Painful or increased urination", 
		"none": "None of these"
	},
	'es': {
		"sob": "Falta de aliento", 
		"chest_discomfort": "Molestia en el pecho", 
		"fatigue": "Fatiga", 
		"weight_change": "Aumento de peso", 
		"confusion": "Confusión", 
		"leg_swelling": "Hinchazón de piernas",
		"lightheaded_falls": "Mareos y caídas",
		"cough": "Tos", 
		"chest_pain": "Dolor de pecho", 
		"fever": "Fiebre", 
		"mucus": "Mucosidad", 
		"wheeze": "Jadeos",
		"shooting_pain": "Dolor punzante hacia bajo del brazo o dolor de un lado del pecho ", 
		"heart_racing": "Aceleración del corazón", 
		"sweating": "Sudoración", 
		"nausea": "Náusea o vómitos", 
		"regurg": "Regurgitación",
		"lightheaded": "Mareos", 
		"poor_sleep": "Mal sueño y ronquidos", 
		"low_mood": "Estado de ánimo depresivo",
		"bowel_changes": "Cambios en las evacuaciones intestinales",
		"unconscious": "Pérdida del conocimiento", 
		"slurred_speech": "Nuevo síntoma de habla confusa", 
		"face_asym": "Nueva asimetría facial", 
		"weakness": "Empeoramiento de debilidad", 
		"urination": "Dolor al orinar o mayor cantidad de orina", 
		"none": "Ninguno de estos"
	}
}

const trafficResultsTrans = {
	'en': {
		"green_title": "Green Zone",
		"green_action": "Keep monitoring",
		"green_desc": "The patient's symptoms indicate that you should continue monitoring for changes.",
		"yellow_title": "Yellow Zone",
		"yellow_action_call": "Call for help",
		"yellow_desc_call": "The patient’s symptoms indicate that you should get help from your supervisor.",
		"yellow_action_consider": "Consider asking for help",
		"yellow_desc_consider": "The patient’s symptoms indicate that you may need help from your supervisor.",
		"red_title": "Red Zone",
		"red_action": "Call 911 or EMS",
		"red_desc": "The patient’s symptoms indicate that this may be an emergency.",
		"symp_summary": "Symptom Summary",
		"review_resources": "Consider reviewing heart failure educational resources.",
		"review_desc": "The patient’s symptoms may be related to heart failure.",
		"waiting": "What you can do while waiting for EMS to arrive:",
		"wait_vitals": "Take the patient’s vitals.",
		"wait_family": "Notify the patient’s family.",
		"wait_super": "Notify your agency supervisor."
	},
	'es': {
		"green_title": "Zona verde",
		"green_action": "Seguir vigilando",
		"green_desc": "Los síntomas del paciente indican que debería continuar vigilando si hay cambios.",
		"yellow_title": "Zona Amarilla",
		"yellow_action_call": "Pida ayuda",
		"yellow_desc_call": "Los síntomas del paciente indican que debería obtener ayuda de su supervisor.",
		"yellow_action_consider": "Considere pedir ayuda",
		"yellow_desc_consider": "Los síntomas del paciente indican que debería obtener ayuda de su supervisor.",
		"red_title": "Zona Roja",
		"red_action": "Llame al 911 o al servicio médico de emergencia",
		"red_desc": "Los síntomas del paciente indican que esto puede ser una emergencia.",
		"symp_summary": "Resumen de síntomas",
		"review_resources": "Considere revisar los recursos educativos de insuficiencia cardíaca.",
		"review_desc": "Los síntomas del paciente pueden estar relacionados con insuficiencia cardíaca.",
		"waiting": "Qué puede hacer mientras espera a que llegue el personal de emergencias:",
		"wait_vitals": "Tome los signos vitales del paciente.",
		"wait_family": "Notifique a la familia del paciente.",
		"wait_super": "Notifique al supervisor de su agencia."
	}
}

const trafficResultsMonitorTrans = {
	'en': {
		"monitoring": "What you can look out for while monitoring:",
		"sob_walk": "Walk the patient around.",
		"sob_walk_action": "Check if shortness of breath is worse with activity.",
		"sob_vitals": "Take patient’s vitals and oxygen saturation.",
		"sob_vitals_action": "If their oxygen saturation is below 95%, call for help.",
		"sob_leg": "Check leg swelling by pressing down on the patient’s leg.",
		"sob_leg_action":"If the imprint stays for a while, their heart failure is likely worsening. Call for help.",
		"chest_vitals": "Take the patient’s blood pressure, watch their breathing, and check their pulse.",
		"chest_vitals_action": "If their pulse is high (over 100 beats in a minute), call for help.",
		"chest_new":"If the patient's chest pain is new and does not go away at rest, call for help. ",
		"fatigue_vitals": "Take the patient's vitals.",
		"fatigue_vitals_action": "If heart rate or blood pressure are abnormal, call for help.",
		"fatigue_other": "Check for leg swelling, changes in weight, or worsening shortness of breath.",
		"fatigue_other_action": "If any of these are also present, this can be a sign of worsening heart failure. Call the patient’s doctor or agency nurse.",
		"weight_check": "Check the patient’s weight.",
		"weight_check_action": "If their weight has rapidly increased or decreased (by more than 3 pounds), call for help.",
		"weight_other": "Check for leg swelling or worsening shortness of breath.",
		"weight_other_action": "If any of these are also present, this can be a sign of worsening heart failure. Ask the patient if they have been taking their heart failure medication, and call the patient’s doctor or agency nurse.",
		"conf_vitals": "Take the patient’s vitals.",
		"conf_vitals_action": "If heart rate or blood pressure are abnormal, call for help.",
		"conf_other": "Check for leg swelling, changes in weight, or worsening shortness of breath.",
		"conf_other_action": "If any of these are also present, this can be a sign of worsening heart failure.",
		"conf_uncommon": "Look out for less common signs of heart failure, like loss of consciousness, cold extremities, and lightheadedness.",
		"conf_uncommon_action": "These may also be signs of worsening heart failure.",
		"conf_hf": "Call the patient’s doctor or agency nurse if you suspect the patient’s confusion is due to heart failure.",
		"leg_indent":"Push on the patient’s leg and see if it indents.",
		"leg_indent_severe":"Further indentation is more severe.",
		"leg_ask":"Ask the patient about diet changes and medication compliance.",
		"leg_vitals":"Take the patient’s vitals and check their weight.",
		"leg_other":"Check for changes in weight, shortness of breath, or other heart failure symptoms.",
		"leg_other_action": "If their leg swelling is worsening and is accompanied by other symptoms, call for help.",
		"leg_not_other_action": "If their leg swelling is slightly increased but not accompanied by other symptoms, continue monitoring.",
		"light_near": "Stay near the patient to prevent any falls.",
		"light_near_detail": "In particular, be very careful to stay near the patient when they are moving about the home or getting up from a seated position.",
		"light_vitals": "Check the patient’s vitals.",
		"light_vitals_action": "Abnormal heart rate or blood pressure should be cause for concern."
	},
	"es": {
		"monitoring": "En qué debe fijarse durante la vigilancia:",
		"sob_walk": "Camine con el paciente.",
		"sob_walk_action": "Verifique si la falta de aliento empeora con la actividad.",
		"sob_vitals": "Tome los signos vitales del paciente y la saturación de oxígeno.",
		"sob_vitals_action": "Si su saturación de oxígeno es inferior a 95%, pida ayuda.",
		"sob_leg": "Revise la hinchazón de piernas oprimiendo en la pierna del paciente.",
		"sob_leg_action":"Si la huella sigue ahí un rato, es probable que la insuficiencia cardíaca esté empeorando. Pida ayuda.",
		"chest_vitals": "Tome la presión arterial del paciente, observe su respiración y revise su pulso.",
		"chest_vitals_action": "Si su pulso es alto (más de 100 latidos por minuto), pida ayuda.",
		"chest_new":"Si el dolor de pecho del paciente es nuevo y no desaparece al estar en reposo, pida ayuda. ",
		"fatigue_vitals": "Tome los signos vitales del paciente.",
		"fatigue_vitals_action": "Si el ritmo cardíaco o la presión arterial son anormales, pida ayuda.",
		"fatigue_other": "Revise la hinchazón de piernas, cambios de peso, o empeoramiento de la falta de aliento.",
		"fatigue_other_action": "Si cualquiera de estos está presente, puede ser una señal de empeoramiento de la insuficiencia cardíaca. Llame al médico del paciente o al enfermero de la agencia.",
		"weight_check": "Verifique el peso del paciente.",
		"weight_check_action": "Si su peso ha aumentado o disminuido rápidamente (más de 3 libras), pida ayuda.",
		"weight_other": "Revise si hay hinchazón de piernas, o empeoramiento de falta de aliento.",
		"weight_other_action": "Si cualquiera de estos está presente, puede ser una señal de empeoramiento de la insuficiencia cardíaca. Pregunte al paciente si ha estado tomando su medicamento para la insuficiencia cardíaca, y llame al médico del paciente o al enfermero de la agencia.",
		"conf_vitals": "Tome los signos vitales del paciente.",
		"conf_vitals_action": "Si el ritmo cardíaco o la presión arterial son anormales, pida ayuda.",
		"conf_other": "Revise la hinchazón de piernas, cambios de peso, o empeoramiento de la falta de aliento.",
		"conf_other_action": "Si cualquiera de estos está presente, puede ser una señal de empeoramiento de la insuficiencia cardíaca.",
		"conf_uncommon": "Busque señales menos comunes de insuficiencia cardíaca, como pérdida del conocimiento, extremidades frías y mareos.",
		"conf_uncommon_action": "Estas también pueden ser señales de empeoramiento de la insuficiencia cardíaca.",
		"conf_hf": "Llame al médico del paciente o al enfermero de la agencia si sospecha que la confusión del paciente es debida a la insuficiencia cardíaca.",
		"leg_indent":"Oprima la pierna del paciente y revise si se queda la marca.",
		"leg_indent_severe":"Una marca más profunda es más severa.",
		"leg_ask":"Pregunte al paciente sobre cambios en la dieta y su cumplimiento con los medicamentos.",
		"leg_vitals":"Tome los signos vitales del paciente y revise su peso.",
		"leg_other":"Verifique si hay cambios en el peso, falta de aliento u otro síntoma de insuficiencia cardíaca.",
		"leg_other_action": "Si su hinchazón de piernas está empeorando y está acompañada de otros síntomas, pida ayuda.",
		"leg_not_other_action": "Si su hinchazón de piernas es un poco más elevada pero no está acompañada de otros síntomas, siga vigilando.",
		"light_near": "Manténgase cerca del paciente para evitar caídas.",
		"light_near_detail": "En particular, manténgase cerca del paciente cuando se esté moviendo en la casa o cuando se levanta después de estar sentado.",
		"light_vitals": "Revise los signos vitales del paciente.",
		"light_vitals_action": "El ritmo cardíaco o la presión arterial anormal debe ser un motivo de inquietud."
	}
}

export { trafficToolTranslations, trafficQuestionTrans, trafficSymptomsTrans, trafficResultsTrans, trafficResultsMonitorTrans };