import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../../libs/contextLib";

import '../CasePages.scss';
import {caseBPTrans} from '../../../translations/casesTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import PageTitle from '../../../components/PageTitle';
import ScenarioBox from '../../../components/ScenarioBox';

export default function CaseBP2(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		window.scrollTo(0, 0)
		setIsLoading(false);
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	return (
		!isLoading && (<div className={"ContentPage CasePage "+screen_size}>
			<PageTitle 
				title={caseBPTrans[language]['h3-question']}
			/>
            <ScenarioBox text={caseBPTrans[language]['h3']}/>
			<div className={"content"}>	
                <h3><FormattedMessage id="h5"/></h3>			
				<p><FormattedMessage id="h3-h1"/></p>
                <h3><FormattedMessage id="h6"/></h3>
				<p><FormattedMessage id="h3-h1-p1"/></p>
				<p><FormattedMessage id="h3-h1-p2"/></p>
				<p><FormattedMessage id="h3-h1-p3"/></p>
				<p><FormattedMessage id="h3-h1-p4"/></p>
				<h3><FormattedMessage id="h3-h2"/></h3>
				<p><FormattedMessage id="h3-h2-p1"/></p>
				<p><FormattedMessage id="h3-h2-p2"/></p>
				<h3><FormattedMessage id="h4"/></h3>
				<p><FormattedMessage id="h4p1"/></p>
				<p><FormattedMessage id="h4p2"/></p>
				<p><FormattedMessage id="h4p3"/></p>
			</div>

		</div>)
    );
}