const NAVBAR_TITLE = "Heart Failure E-learning";

const GOOGLE_TRACKING_ID = "UA-224057804-1"; // NEED TO UPDATE UA GOOGLE ID, 
//dev:UA-232684906-1, real:UA-224057804-1 
const GA4_MEASUREMENT_ID = 'G-TQHGDSVV0L' // 'G-TQHGDSVV0L' for real 

export { 
	NAVBAR_TITLE,
	GOOGLE_TRACKING_ID,
	GA4_MEASUREMENT_ID
}