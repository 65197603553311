import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAppContext } from "../../../libs/contextLib";

import '../BasicsPages.scss';
import './HeartPump.scss';
import {heartpumpTranslations} from '../../../translations/basicsTranslations';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl'
import ReactGA from 'react-ga4';
import { useCookies } from 'react-cookie';

import PageTitle from '../../../components/PageTitle';
import ScenarioBox from '../../../components/ScenarioBox';

export default function HeartPump(props){
	const [cookies1, setCookie1,] = useCookies(['uid']);
	useEffect(() => {
		if (cookies1.uid !== '' && typeof cookies1.uid !== 'undefined'){
			//ReactGA.set({ userId: cookies1.uid });
			ReactGA.gtag("set", "user_properties", {
				user_id: cookies1.uid,
				user_report_id: cookies1.uid
			});
		}
		//ReactGA.pageview(window.location.pathname);
		//ReactGA.send({ hitType: "pageview", page: window.location.pathname});
		//console.log(language)
		window.scrollTo(0, 0)
		setIsLoading(false);
	}, []);
	

	const history = useHistory();
	const screen_size = props.size;
	const { language } = useAppContext();
	const [isLoading, setIsLoading] = useState(true);

	return (
		!isLoading && (<div className={"ContentPage BasicsPages "+screen_size}>

			<div className={"content"}>
				<h3><FormattedMessage id="h1"/></h3>
				{ language === "es" ? 
  					<img src = "/basics_img/heartpumps_well_s.jpg" class="center" className = "heart_pumpswell"></img> : 
  					<img src = "/basics_img/heartpumps_well.png" class="center" className = "heart_pumpswell"></img>}
				<p><li><FormattedMessage id="h1p1"/></li></p>
				<p><li><FormattedMessage id="h1p2"/></li></p>
				<p><li><FormattedMessage id="h1p3"/></li></p>
				<p><li><FormattedMessage id="h1p4"/></li></p>
				</div>

		</div>
    ));
}