import React from 'react';
import { Breakpoint } from 'react-socks'
import { IntlProvider } from 'react-intl'
import { useAppContext } from "../../../libs/contextLib";

import HeartNoPump from './HeartNoPump';

import { heartnopumpTranslations } from '../../../translations/basicsTranslations'

export default function HeartNoPumpContainer(){
	const { language } = useAppContext();
	return (
    	<IntlProvider locale={language} messages={heartnopumpTranslations[language]} >
	    	<div>
		    	<Breakpoint small up>
		    		<HeartNoPump size="SmallUp"/> 
				</Breakpoint>
				<Breakpoint xsmall only>
					<HeartNoPump size="XSmall"/>
				</Breakpoint>
			</div>
		</IntlProvider>
    );
}