import React from 'react';
import './ScenarioBox.scss';

export default function ScenarioBox(props){

	return(
		<div className="ScenarioBox">
		
			{props.text}
		</div>
	);

}