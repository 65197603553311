import React from 'react';
import { useAppContext } from "../libs/contextLib";

export default function NotFound() {
	const { language } = useAppContext();
	return (
		<div className="NotFound">
			{ language === "es" ? 
			<h3>Lo siento, no se encontró la página</h3>:
			<h3>Sorry, page not found!</h3>
			}
		</div>
	)
}