const casesTranslations = {
	'en': {
		"title": "Aspects of Heart Failure Care",
		"title_helptext": "Real life examples of how to care for your patients with heart failure",
		"bp": "Monitoring Blood Pressure", 
		"swelling": "Leg Swelling",
		"weightgain": "Monitoring Changes in Weight",
		"medication": "Common Medications", 
		"exercise": "Getting Exercise", 
		"diet": "Eating Well",
		"visit":"Preparing for Doctor Visits "
	},
	'es': {
		"title": "Aspectos del cuidado de la insuficiencia cardíaca",
		"title_helptext": "Ejemplos de la vida real de cómo cuidar a sus pacientes con insuficiencia cardíaca ",
		"bp": "Vigilar la presión arterial", 
		"swelling": "Hinchazón de piernas",
		"weightgain": "Vigilar cambios en el peso ",
		"medication": "Medicamentos comunes", 
		"exercise": "Hacer ejercicio ", 
		"diet": "Comer bien",
		"visit":"Prepararse para las visitas al médico"
	}
}

const caseBPTrans = {
	'en': {
		"title": "Monitoring Blood Pressure",
		'bp-helper-text':"It is important to monitor blood pressure.",
		"h1": "How to take blood pressure?",
		"h1p1": "Blood pressure has 2 numbers, the systolic (top #) and the diastolic (bottom #).",
		"h1p2": "Ask the patient to sit in a chair with feet flat on the ground.",
		"h1p3": "Ask the patient to breathe normally and be still.",
		"h1p4": "Place the cuff directly on the skin, not over clothing. The cuff should be tight enough to not slip down, but not uncomfortably tight.",
		"h1p5": "Let the patient sit and relax for about 3 to 5 minutes with the cuff on.",
		"h1p6": "Follow the directions that came with the device to start measuring the blood pressure.",
		"h1p7": "This might involve squeezing the bulb to inflate the cuff (fill it with air), or it may mean pressing a button to inflate the cuff. Stop inflating the cuff when it becomes too tight.",
		"h1p8": "Slowly deflate the cuff (let the air out of it), or it will deflate by itself. The screen or dial will show the patient's blood pressure numbers.",
		"h2": "What is a normal blood pressure?",
		"button-text":"Check your understanding with a case",
		"h3-question":"A Blood Pressure Knowledge Question",
		"h3": "Your patient Mr. C has blood pressure of 168/90 and heart rate 98.",
		"h5": "Is this normal?",
		"h3-h1": "Answer: No, blood pressure and pulse are elevated ",
		"h6": "What to think about?",
		"h3-h1-p1": "Compare to baseline (what is the normal blood pressure?)",
		"h3-h1-p2": "Ask if medications have been taken",
		"h3-h1-p3": "Ask about dietary changes (too much salt?)",
		"h3-h1-p4": "Ask if he is having symptoms?",
		"h3-h2": "What would you do?",
		"h3-h2-p1": "Take the blood pressure again",
		"h3-h2-p2": "Call for help if there are any other concerning symptoms",
		"h4": "Key points to remember",
		"h4p1":"Vital signs are important",
		"h4p2":"Know other diseases that heart failure patients may have",
		"h4p3":"Always ask for help if there is any concern"
	},
	'es': {
		"title": "Vigilar la presión arterial",
		'bp-helper-text':"Es importante vigilar la presión arterial.",
		"h1": "¿Cómo se toma la presión arterial?",
		"h1p1": "La presión arterial tiene 2 números, el sistólico (número superior) y el diastólico (número inferior).",
		"h1p2": "Pida al paciente que se sienta en una silla con los pies planos en el suelo.",
		"h1p3": "Pida al paciente que respire normalmente y que se mantenga quieto.",
		"h1p4": "Coloque el manguito directamente en la piel, no sobre la ropa. El manguito debe estar lo suficientemente apretado para que no se resbale, pero no incómodamente apretado.",
		"h1p5": "Deje que el paciente esté sentado de 3 a 5 minutos con el manguito puesto.",
		"h1p6": "Siga las instrucciones que vienen con el dispositivo para empezar a medir la presión arterial.",
		"h1p7": "Esto puede incluir apretar el bulbo para inflar el manguito (llenarlo con aire), o puede significar oprimir un botón para inflar el manguito. Deje de inflar el manguito cuando se vuelva demasiado apretado.",
		"h1p8": "Desinfle lentamente el manguito (deje salir el aire), o se desinflará solo. La pantalla o el marcador indicará los valores de presión arterial del paciente.",
		"h2": "¿Cuál es la presión arterial normal? ",
		"button-text":"Verifique su comprensión con un caso ",
		"h3-question":"Una pregunta de Conocimientos de la presión arterial.",
		"h3": "Su paciente, el Sr. C, tiene una presión arterial de 168/90 y un ritmo cardíaco de 98. ",
		"h5": "¿Es esto normal?",
		"h3-h1": "Respuesta: No, la presión arterial y el pulso están elevados ",
		"h6": "¿Qué se debe pensar?",
		"h3-h1-p1": "En comparación con el punto de referencia (¿cuál es la presión arterial normal?)",
		"h3-h1-p2": "Pregunte si ha tomado medicamentos",
		"h3-h1-p3": "Pregunte sobre cambios en la dieta (¿demasiada sal?)",
		"h3-h1-p4": "Pregunte si tiene síntomas?",
		"h3-h2": "¿Qué debe hacer?",
		"h3-h2-p1": "Tome la presión nuevamente",
		"h3-h2-p2": "Pida ayuda si hay otro síntoma que cause preocupación",
		"h4": "Puntos clave a recordar",
		"h4p1":"Los signos vitales son importantes",
		"h4p2":"Conozca otras enfermedades que los pacientes con insuficiencia cardíaca pueden tener",
		"h4p3":"Siempre pida ayuda si hay alguna inquietud"
	}
}

const caseVisitTrans = {
	'en': {
		"title": "Preparing for Doctor Visits ",
		"overview": "You may accompany your patient to the doctor. You need to double-check what items to bring or what information to share.",
		"h1": "Remind them to share:",
		"h1p1": "How they have been feeling (any new symptoms)",
		"h1p2": "List of medical issues",
		"h1p3": "Questions they have about their health",
		"h2": "Items to remind them to bring",
		"h2p1": "Insurance card (if you have one)",
		"h2p2": "ID card (if you have one)",
		"h2p3": "Medications. You may write a list of them or just bring the bottles. Include non-prescription medicine"
	},
	'es': {
		"title": "Prepararse para las visitas al médico ",
		"overview": "Puede acompañar al paciente el médico. Necesita verificar qué artículos llevar o qué información compartir. ",
		"h1": "Recuérdele compartir:",
		"h1p1": "Cómo se ha sentido (algún síntoma nuevo) ",
		"h1p2": "Lista de problemas médicos",
		"h1p3": "Preguntas que pueda tener sobre su salud ",
		"h2": "Artículos para recordarles que deben traer ",
		"h2p1": "Recordarle que debe llevar tarjeta de seguro (si tiene una)",
		"h2p2": "Tarjeta de identificación (si tiene una)",
		"h2p3": "Medicamentos. Puede hacer una lista de ellos o llevar los frascos. Incluya medicamentos sin receta"
	}
}

const caseDietTrans = {
	'en': {
		"title": "Eating Well",
		"diet-overview": "It is important for patients with heart failures to eat plenty of fresh fruits, vegetables and foods that are low in salt.",
		"h1": "What food are salty?",
		"h1p1": "Low-salt items: apples, pasta, tomatoes, chicken breast, frozen peas and carrots",
		"h1p2": "High-salt items: spaghetti sauce, canned spam, hot dogs, beans, rice, frozen pizza, lunch meat, bread",
		"h1p3": "To learn more check out this ",
		"h1p4": "PALS article",
		"h2": "General rules regarding salt",
		"h2p1": "Unprocessed natural food, like raw fruits and vegetables, are low salt food.",
		"h2p2": "Processed meat (deli meats, spam, etc), pre-made meals (canned soup and frozen meals), and junk food (chips, pretzels) are usually high salt",
		"h3": "Reading a nutrition label",
		'case': "You may need to go food shopping for your patients. Let's review how to read a nutrition label.",
		"h4": "Test your knowledge",
		"h4q1": "What is the serving size?",
		"h3p1": "Serving size = 1/6 pizza, which means the portion of food used as a reference on the nutrition label",
		"h4q2": "Is this food salty?",
		"h3p2": "Sodium level = 840mg, 36% for one teaspoon of salt(2300mg). Most guidelines suggest 1500mg of salt per day. The less salt the better for Heart Failure patients"
	},
	'es': {
		"title": "Comer bien",
		"diet-overview": "Es importante que los pacientes con insuficiencia cardíaca coman una cantidad generosa de frutas y verduras frescas y alimentos con bajo contenido de sal.",
		"h1": "¿Qué alimentos son salados?",
		"h1p1": "Alimentos con bajo contenido de sal: manzanas, pasta, tomates, pechuga de pollo, chícharos y zanahorias congelados",
		"h1p2": "Alimentos con alto contenido de sal: salsa de espagueti, spam en lata, hot dogs, frijoles, arroz, pizza congelada, carnes frías, pan",
		"h1p3": "Para obtener más información sobre esto ",
		"h1p4": "artículo PALS" ,
		"h2": "Reglas generales con respecto a la sal",
		"h2p1": "Los alimentos naturales no procesados, como frutas y verduras crudos, tienen bajo contenido de sal.",
		"h2p2": "Las carnes procesadas (carnes deli, spam, etc.), alimentos preparados previamente (sopas enlatadas y comidas congeladas), y comida chatarra (papitas, pretzels) por lo general tienen alto contenido de sal",
		"h3": "Leer una etiqueta de nutrición",
		'case': "Quizá tenga que comprar la comida para sus pacientes. Veamos cómo interpretar una etiqueta de nutrición.",
		"h4": "Pruebe sus conocimientos",
		"h4q1": "¿Cuál es el tamaño de la porción?",
		"h3p1": "Tamaño de porción = 1/6 pizza, lo cual significa la porción de comida usada como referencia en la etiqueta de nutrición",
		"h4q2": "¿Es este alimento salado?",
		"h3p2": "Nivel de sodio = 840 mg, 36% para una cucharadita de sal (2300 mg). La mayoría de los lineamientos sugieren 1500 mg de sal al día. Mientras menos sal es mejor para los pacientes con insuficiencia cardíaca"

	}
}

const caseExerciseTrans = {
	'en': {
		"title": "Getting Exercise",
		"helper-text-1":"Exercise is generally safe for adults with heart failure.",
		"helper-text-2":"Exercise can improve breathing, blood pressure, and blood flow. It may also improve symptoms.",
		"helper-text-3":"Choosing the right type of exercise depends on the client’s health and ability.",
		"pals-link-text1":"Learn more about exercise planning for heart failure patients",
		"button-text":"Check your understanding with a case",
		"overview": "Mrs. W is a 80 year-old woman with heart failure. She uses cane to move and had two falls last year. She is in depressed mood and not motivated to go outside.",
		"h1": "Formulating an activity plan, what do we need to think about?",
		"h1p1": "How did she fall in the past? Why? ",
		"h1p2": "Has she been to the doctor recently? Any new diagnoses?",
		"h1p3": "Frailty: Previous injuries or activity limitation, osteoporosis (fragile bones)?",
		"h1p4": "What is her baseline? How far can she normally walk, willing to leave home?",
		"h1p5": "Is she willing or able to leave the home?",
		"h2": "Coming up with patient-directed activity plan",
		"h2p1_1": "Important points:", 
		"h2p1_2": "Always have a baseline for your patient’s activity, behavior, and medical conditions.",
		"h2p2": "Practice motivational interviewing techniques on developing a plan to engage the patient in physical activity that feels right for her.",
		"h2p2-p1":"Try to motivate your patient to get physical activities that make sense for you.",
		"h2p2-p2":"Examples: Try walking with her to her favorite neighborhood places. Help her with range of motion exercises in the home to build strength"
	},
	'es': {
		"title": "Hacer ejercicio",
		"helper-text-1":"El ejercicio por lo general es seguro para adultos con insuficiencia cardíaca.",
		"helper-text-2":"El ejercicio puede mejorar la respiración, la presión arterial y el flujo de sangre. También puede mejorar los síntomas.",
		"helper-text-3":"Elegir el tipo correcto de ejercicio depende de la salud y la habilidad del paciente. ",
		"pals-link-text1":"Aprenda más sobre la planificación de ejercicios para pacientes con insuficiencia cardíaca.",
		"button-text":"Verifique su compresión con un caso",
		"overview": "La Sra. W tiene 80 años y tiene insuficiencia cardíaca. Usa un bastón para moverse y el año pasado tuvo dos caídas. Se siente deprimida y no está motivada para salir al aire libre.",
		"h1": "Para formular un plan de actividades, ¿en qué necesitamos pensar?",
		"h1p1": "¿Cómo se cayó en el pasado? ¿Por qué? ",
		"h1p2": "¿Ha visto al médico recientemente? ¿Algún nuevo diagnóstico?",
		"h1p3": "Fragilidad: ¿Lesiones previas o limitación de actividades, osteoporosis (huesos frágiles)? ",
		"h1p4": "¿Cuál es su punto de referencia? ¿Qué tanto puede caminar normalmente, está dispuesta a salir de casa?",
		"h1p5": "¿Está dispuesta o puede salir de casa?",
		"h2": "Cómo planear actividades específicas para el paciente ",
		"h2p1_1": "Puntos importantes:", 
		"h2p1_2": "Siempre tenga un punto de referencia de la actividad de su paciente, su comportamiento y condiciones médicas.",
		"h2p2": "Practique técnicas de entrevistas motivacionales para desarrollar un plan y motivar a la paciente a hacer actividades físicas que sean adecuadas para ella.",
		"h2p2-p1":"Trate de motivarla para que haga actividades físicas que tengan sentido para usted.",
		"h2p2-p2":"Ejemplos: Intente caminar con ella a sus lugares favoritos de su barrio. Ayúdele con una variedad de ejercicios de movimiento en la casa para que desarrolle fuerza"
	}
}

const caseMedicationTrans = {
	'en': {
		"title": "Common Medications",
		"m-helper-text": "Patients with heart failure take many medications to control their symptoms and help their heart. There are different types of medications, which work differently on the body.",
		"h1": "Medications that control hormones that make heart failure worse",
		"h1p1": "ACE-inhibitor (lisinopril)",
		"h1p2": "ARB (valsartan)",
		"h1p3": "Neprilysin-Inhibitor and ARBs (Entresto)",
		"h1p4": "Aldosterone inhibitors (Spironolactone)",
		"h2": "Medications that slow heart rate and relax blood vessels",
		"h2p1": "Beta-Blockers (Metoprolol)",
		"h2p2": "ACE-Inhibitors/ARB ",
		"h2p3": "Hydralazine",
		"h2p4": "Nitrate",
		"h3": "Medications that decrease swelling (water pills)",
		"h3p1": "Diuretics like Furosemide, hydrochlorothiazide",
		"h3-header":"Spotlight: Furosemide (Lasix)",
		"lasix-info":"Many heart failure patients have too much fluid in their bodies, this can lead to shortness of breath and leg swelling. Lasix is a water pill that removes fluid. It does have side effects and it can affect the kidneys. People starting this medication may need to be monitored.",
		"h4": "Medications that prevent blockages in the heart and blood vessels",
		"h4p1": "Aspirin",
		"h4p2": "Clopidogrel (Plavix)",
		"h5": "Medications that reduce Cholesterol",
		"h5p1": "Statins (atorvastatin)",
	},
	'es': {
		"title": "Medicamentos comunes",
		"m-helper-text": "Los pacientes con insuficiencia cardíaca toman muchos medicamentos para controlar sus síntomas y ayudar a su corazón. Hay diferentes tipos de medicamentos, los cuales funcionan de diferente manera en el cuerpo.",
		"h1": "Medicamentos que controlan las hormonas que empeoran la insuficiencia cardíaca",
		"h1p1": "Inhibidor ACE (lisinopril)",
		"h1p2": "ARB (valsartan)",
		"h1p3": "Neprilysin-Inhibitor and ARBs (Entresto)",
		"h1p4": "Inhibidores de aldosterona (Spironolactone)",
		"h2": "Medicamentos que reducen el ritmo cardíaco y relajan los vasos sanguíneos",
		"h2p1": "Bloqueadores beta (Metoprolol)",
		"h2p2": "Inhibidores ACE/ARB ",
		"h2p3": "Hidralazina",
		"h2p4": "Nitrato",
		"h3": "Medicamentos que reducen la hinchazón (diuréticos) ",
		"h3p1": "Diuréticos como Furosemida, hidroclorotiazida",
		"h3-header":"Punto destacado: Furosemida (Lasix)",
		"lasix-info":"Muchos pacientes con insuficiencia cardíaca tienen demasiado líquido en el cuerpo, y esto puede causar falta de aliento e hinchazón de piernas. Lasix es un diurético que elimina el líquido. Tiene efectos secundarios y puede afectar los riñones. La gente que empieza a tomar este medicamento tiene que ser supervisada.",
		"h4": "Los medicamentos que prevén bloqueos en el corazón y en los vasos sanguíneos",
		"h4p1": "Aspirina",
		"h4p2": "Clopidogrel (Plavix)",
		"h5": "Medicamentos que recuden el colesterol ",
		"h5p1": "Estatinas (atorvastatina)",
	}
}

const caseSwellingTrans = {
	'en': {
		"title": "Leg Swelling",
		"swelling-helper-text":"The tighter the feeling, the more severe the swelling.",
		"overview": "Case you notice: Mrs. S has swelling in her feet and ankles. What should you do?",
		"h1": "How to check for swelling (edema)",
		"h1-h1": "Press down on their legs with 1 finger see if an indent remains",
		"h1-h1-p1": "The deeper the indent, the more severe the swelling",
		"h1-h1-p2": "The higher up the leg the swelling goes, the more severe it is",
		"h1-h2": "Ask patients if their shoes and socks feel tighter than normal.",
		"h1-h2-p1": "The tighter feeling, the more severe the edema",
		"h1p3": "To learn more check out this ", 
		"h1p4": "PALS article",
		"h3": "What would you do?",
		"h3-h1-p1": "Get a sense of patient's baseline (do they normally have swelling, or is this worse?)",
		"h3-h1-p2": "Check weights, oxygen level, blood pressure, and temperature.",
		"h3-h1-p3": "Look at how quickly they are breathing (respiratory rate)",
		"h3-h1-p4": "If vital signs (oxygen level, breathing rate, or weight) are concerning, reach out to your agency for help"
	},
	'es': {
		"title": "Hinchazón de piernas",
		"swelling-helper-text":"Mientras más tirante sea la sensación, la hinchazón es más severa.",
		"overview": "Caso que observa: La Sra. S. tiene hinchazón en los pies y tobillos. ¿Qué debe hacer? ",
		"h1": "Cómo revisar si hay hinchazón (edema)",
		"h1-h1": "Presione las piernas con un dedo para ver si se queda oprimido ",
		"h1-h1-p1": "Mientras más profunda sea la opresión, más severa es la hinchazón",
		"h1-h1-p2": "Mientras más alta sea la hinchazón en la pierna, más severa es",
		"h1-h2": "Pregunte a los pacientes si sus zapatos y calcetines se sienten más apretados de lo normal.",
		"h1-h2-p1": "Mientras más tirante sea la sensación, el edema es más severo.",
		"h1p3": "Para obtener más información sobre esto ",
		"h1p4": "artículo PALS", 
		"h3": "¿Qué debe hacer?",
		"h3-h1-p1": "Tome en cuenta el punto de referencia del paciente (¿normalmente tiene hinchazón o es peor?) ",
		"h3-h1-p2": "Revise el peso, el nivel de oxígeno, la presión arterial y la temperatura.",
		"h3-h1-p3": "Fíjese qué tan rápido está respirando (frecuencia respiratoria)",
		"h3-h1-p4": "Si los signos vitales (nivel de oxígeno, frecuencia de respiración o peso) son inquietantes, pida ayuda a su agencia"
	}
}

const caseWGTrans = {
	'en': {
		"title": "Monitoring Changes in Weight",
		"wg-helper-text":"Patients with heart failure may gain weight because they are retaining fluid.",
		"case-header-text": "Check your understanding with a case",
		"overview": "Your patient's weight is up 2lbs today. She had forgotten to take medication for 2 days. Her oxygen saturation is 96%.",
		"h1": "What do these vitals mean?",
		"h1p1": "Weight increase may be due to fluid retention",
		"h1p2": "Breathing seems to be ok",
		"h1p3": "To learn more check out this ", 
		"h1p4": "PALS article",
		"h2": "Why might this be happening?",
		"h2p1": "Not taking medication has led to fluid retention",
		"h3": "What would you do?",
		"h3p1": "Have the patient take medications",
		"h3p2": "Ask agency or doctor whether extra water pill doses are necessary",
		"h3p3": "If the patient feels more short of breath, ask for help",
		"h4": "Important Knowledge Points",
		"h4p1": "Knowing your patient's baseline will make your caring job safer",
		"h4p2": "Vital signs such as weight and oxygen level are helpful to determine how severe symptoms are",
		"h4p3": "Fluid retention and shortness of breath indicate that heart failure is not well controlled. ",
		"h4p4": "Taking medication regularly is important",
		"h4p5": "Always ask for help if there is any concern"

	},
	'es': {
		"title": "Vigilar cambios de peso",
		"wg-helper-text":"Los pacientes con insuficiencia cardíaca pueden aumentar de peso porque retienen líquido.",
		"case-header-text": "Verifique su compresión con un caso",
		"overview": "El peso de la paciente subió 2 libras hoy. Se olvidó tomar el medicamento 2 días. Su saturación de oxígeno es 96%.",
		"h1": "¿Qué significan estos signos vitales?",
		"h1p1": "El aumento de peso puede ser debido a retención de líquido ",
		"h1p2": "La respiración parece estar bien",
		"h1p3": "Para obtener más información sobre esto ",
		"h1p4": "artículo PALS",
		"h2": "¿Por qué puede estar pasando esto?",
		"h2p1": "No tomar el medicamento causó la retención de líquido ",
		"h3": "¿Qué debe hacer?",
		"h3p1": "Pedir al paciente que tome los medicamentos",
		"h3p2": "Preguntar a la agencia o al médico si es necesario que tome diuréticos adicionales",
		"h3p3": "Si el paciente tiene más dificultad para respirar, pida ayuda",
		"h4": "Puntos importantes de conocimiento",
		"h4p1": "Conocer el punto de referencia del paciente hará que su trabajo de cuidador sea más seguro",
		"h4p2": "Los signos vitales, como el peso y el nivel de oxígeno son útiles para determinar qué tan severos son los síntomas",
		"h4p3": "La retención de líquidos y la falta de aliento indican que la insuficiencia cardíaca no está bien controlada. ",
		"h4p4": "Es importante tomar el medicamento con regularidad",
		"h4p5": "Siempre pida ayuda si hay alguna inquietud"
	}
}


export { 
	casesTranslations, 
	caseBPTrans, caseDietTrans, caseExerciseTrans, caseVisitTrans,
	caseMedicationTrans, caseSwellingTrans, caseWGTrans};
