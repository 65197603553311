import {trafficQuestionTrans, trafficSymptomsTrans} from './../../../translations/trafficToolTranslations';
import { FormattedMessage } from 'react-intl'

// FIRST QUESTION
export function GetFirstQ(language){
	const first_q = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["first_q"]}/>, 
		id: 0,
		type: "SELECTCHOICE",
		choice_ids: ["sob", "chest_discomfort", "fatigue", "weight_change", "confusion", "leg_swelling", "lightheaded"],
		choice_labels: {
			"sob": <FormattedMessage id={trafficSymptomsTrans[language]["sob"]}/>, 
			"chest_discomfort": <FormattedMessage id={trafficSymptomsTrans[language]["chest_discomfort"]}/>,
			"fatigue": <FormattedMessage id={trafficSymptomsTrans[language]["fatigue"]}/>, 
			"weight_change": <FormattedMessage id={trafficSymptomsTrans[language]["weight_change"]}/>, 
			"confusion": <FormattedMessage id={trafficSymptomsTrans[language]["confusion"]}/>, 
			"leg_swelling": <FormattedMessage id={trafficSymptomsTrans[language]["leg_swelling"]}/>, 
			"lightheaded": <FormattedMessage id={trafficSymptomsTrans[language]["lightheaded_falls"]}/>, 
		},
		has_conditional: false
	};

	return [first_q];
}

// SHORTNESS OF BREATH QUESTIONS
export function GetSobQs(language){

	const sob_0 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["sob_q0"]}/>,
		id: 0,
		type: "YESNO",
		has_conditional: false
	};
	
	const sob_1 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["sob_q1"]}/>,
		id: 1,
		type: "MULTICHOICE",
		choice_ids: ["cough", "leg_swelling", "chest_pain", "fever", "mucus", "wheeze", "none"],
		choice_labels: {
			"cough": <FormattedMessage id={trafficSymptomsTrans[language]["cough"]}/>,
			"leg_swelling": <FormattedMessage id={trafficSymptomsTrans[language]["leg_swelling"]}/>,
			"chest_pain": <FormattedMessage id={trafficSymptomsTrans[language]["chest_pain"]}/>,
			"fever": <FormattedMessage id={trafficSymptomsTrans[language]["fever"]}/>,
			"mucus": <FormattedMessage id={trafficSymptomsTrans[language]["mucus"]}/>,
			"wheeze": <FormattedMessage id={trafficSymptomsTrans[language]["wheeze"]}/>,
			"none": <FormattedMessage id={trafficSymptomsTrans[language]["none"]}/>
		},
		has_conditional: true,
		conditional_choices: ["chest_pain"],
		conditional_info: {
			"chest_pain": {
				"sub_question": <FormattedMessage id={trafficQuestionTrans[language]["sob_q1_sub"]}/>,
				"sub_ids": ["severe", "not_severe"],
				"sub_labels": {
					"severe": <FormattedMessage id={trafficQuestionTrans[language]["severe"]}/>,
					"not_severe": <FormattedMessage id={trafficQuestionTrans[language]["not_severe"]}/>
				}
			}
		}
	};
	
	const sob_2 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["sob_q2"]}/>,
		id: 2,
		type: "YESNO",
		has_conditional: false
	};
	
	const sob_3 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["sob_q3"]}/>,
		id: 3,
		type: "YESNO",
		has_conditional: false
	};

	return [sob_0, sob_1, sob_2, sob_3]
}

// CHEST DISCOMFORT QUESTIONS
export function GetChestQs(language){
	
	const chest_0 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["chest_q0"]}/>,
		id: 0,
		type: "YESNO",
		has_conditional: false
	};
	
	const chest_1 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["chest_q1"]}/>,
		id: 1,
		type: "MULTICHOICE",
		choice_ids: ["shooting_pain", "sob", "heart_racing", "sweating", "nausea", "regurg", "none"],
		choice_labels: {
			"shooting_pain": <FormattedMessage id={trafficSymptomsTrans[language]["shooting_pain"]}/>, 
			"sob": <FormattedMessage id={trafficSymptomsTrans[language]["sob"]}/>, 
			"heart_racing": <FormattedMessage id={trafficSymptomsTrans[language]["heart_racing"]}/>, 
			"sweating": <FormattedMessage id={trafficSymptomsTrans[language]["sweating"]}/>, 
			"nausea": <FormattedMessage id={trafficSymptomsTrans[language]["nausea"]}/>, 
			"regurg": <FormattedMessage id={trafficSymptomsTrans[language]["regurg"]}/>,
			"none": <FormattedMessage id={trafficSymptomsTrans[language]["none"]}/>
		},
		has_conditional: false
	};
	
	const chest_2 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["chest_q2"]}/>,
		id: 2,
		type: "SELECTCHOICE",
		choice_ids: ["active", "rest"],
		choice_labels: {
			"active": <FormattedMessage id={trafficQuestionTrans[language]["active"]}/>,
			"rest": <FormattedMessage id={trafficQuestionTrans[language]["rest"]}/>
		},
		has_conditional: false
	};

	return [chest_0, chest_1, chest_2];
}

// FATIGUE QUESTIONS
export function GetFatigueQs(language){

	const fatigue_0 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["fatigue_q0"]}/>,
		id: 0,
		type: "MULTICHOICE",
		choice_ids: ["fever", "sob", "weight_change", "leg_swelling", "cough", "lightheaded","poor_sleep","low_mood","none"],
		choice_labels: {
			"fever": <FormattedMessage id={trafficSymptomsTrans[language]["fever"]}/>,
			"sob": <FormattedMessage id={trafficSymptomsTrans[language]["sob"]}/>,
			"weight_change": <FormattedMessage id={trafficSymptomsTrans[language]["weight_change"]}/>,
			"leg_swelling": <FormattedMessage id={trafficSymptomsTrans[language]["leg_swelling"]}/>, 
			"cough": <FormattedMessage id={trafficSymptomsTrans[language]["cough"]}/>,
			"lightheaded": <FormattedMessage id={trafficSymptomsTrans[language]["lightheaded"]}/>,
			"poor_sleep": <FormattedMessage id={trafficSymptomsTrans[language]["poor_sleep"]}/>,
			"low_mood": <FormattedMessage id={trafficSymptomsTrans[language]["low_mood"]}/>,
			"none": <FormattedMessage id={trafficSymptomsTrans[language]["none"]}/>,
		},
		has_conditional: false
	};

	const fatigue_1 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["fatigue_q1"]}/>,
		id: 1,
		type: "YESNO",
		has_conditional: false
	};

	const fatigue_2 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["fatigue_q2"]}/>,
		id: 2,
		type: "YESNO",
		has_conditional: false
	};

	const fatigue_3 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["fatigue_q3"]}/>,
		id: 3,
		type: "YESNO",
		has_conditional: false
	};

	return [fatigue_0, fatigue_1, fatigue_2, fatigue_3]
}

// WEIGHT GAIN QS
export function GetWeightQs(language){

	const weight_0 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["weight_q0"]}/>,
		id: 0,
		type: "SELECTCHOICE",
		choice_ids: ["day_gain", "week_gain","other"],
		choice_labels: {
			"day_gain": <FormattedMessage id={trafficQuestionTrans[language]["day_gain"]}/>,
			"week_gain": <FormattedMessage id={trafficQuestionTrans[language]["week_gain"]}/>,
			"other": <FormattedMessage id={trafficQuestionTrans[language]["other"]}/>,
		}
	};

	const weight_1 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["weight_q1"]}/>,
		id: 1,
		type: "YESNO",
		has_conditional: false
	};

	const weight_2 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["weight_q2"]}/>,
		id: 2,
		type: "MULTICHOICE",
		choice_ids: ["sob", "cough", "leg_swelling", "nausea", "bowel_changes"],
		choice_labels: {
			"sob": <FormattedMessage id={trafficSymptomsTrans[language]["sob"]}/>,
			"cough": <FormattedMessage id={trafficSymptomsTrans[language]["cough"]}/>,
			"leg_swelling": <FormattedMessage id={trafficSymptomsTrans[language]["leg_swelling"]}/>,
			"nausea": <FormattedMessage id={trafficSymptomsTrans[language]["nausea"]}/>,
			"bowel_changes": <FormattedMessage id={trafficSymptomsTrans[language]["bowel_changes"]}/>
		},
		has_conditional: false
	};

	return [weight_0,weight_1,weight_2];
}

// CONFUSION QS
export function GetConfusionQs(language){

	const confusion_0 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["confusion_q0"]}/>,
		id: 0,
		type: "YESNO",
		has_conditional: false
	};

	const confusion_1 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["confusion_q1"]}/>,
		id: 1,
		type: "YESNO",
		has_conditional: false
	};

	const confusion_2 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["confusion_q2"]}/>,
		id: 2,
		type: "MULTICHOICE",
		choice_ids: ["unconscious", "slurred_speech", "face_asym", "weakness", "fever", "urination", "lightheaded", "none"],
		choice_labels: {
			"unconscious": <FormattedMessage id={trafficSymptomsTrans[language]["unconscious"]}/>,
			"slurred_speech": <FormattedMessage id={trafficSymptomsTrans[language]["slurred_speech"]}/>,
			"face_asym": <FormattedMessage id={trafficSymptomsTrans[language]["face_asym"]}/>,
			"weakness": <FormattedMessage id={trafficSymptomsTrans[language]["weakness"]}/>,
			"fever": <FormattedMessage id={trafficSymptomsTrans[language]["fever"]}/>,
			"urination": <FormattedMessage id={trafficSymptomsTrans[language]["urination"]}/>,
			"lightheaded": <FormattedMessage id={trafficSymptomsTrans[language]["lightheaded"]}/>,
			"none": <FormattedMessage id={trafficSymptomsTrans[language]["none"]}/>,
		},
		has_conditional: false
	};

	const confusion_3 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["confusion_q3"]}/>,
		id: 3,
		type: "YESNO",
		has_conditional: false
	};

	return [confusion_0, confusion_1, confusion_2, confusion_3];
}

// LEG SWELLING QS

export function GetLegSwellingQs(language){
	const leg_0 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["leg_q0"]}/>,
		id: 0,
		type: "YESNO",
		has_conditional: false
	};

	const leg_1 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["leg_q1"]}/>,
		id: 1,
		type: "YESNO",
		has_conditional: false
	};

	const leg_2 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["leg_q2"]}/>,
		id: 2,
		type: "YESNO",
		has_conditional: false
	};

	const leg_3 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["leg_q3"]}/>,
		id: 3,
		type: "SELECTCHOICE",
		choice_ids: ["one", "both"],
		choice_labels: {
			"one": <FormattedMessage id={trafficQuestionTrans[language]["one"]}/>,
			"both": <FormattedMessage id={trafficQuestionTrans[language]["both"]}/>
		},
		has_conditional: false
	};

	const leg_4 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["leg_q4"]}/>,
		id: 4,
		type: "YESNO",
		has_conditional: false
	};

	return [leg_0,leg_1,leg_2,leg_3,leg_4];
}
// LIGHTHEADEDNESS AND FALLS QS

export function GetLightheadFallQs(language){

	const light_0 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["light_q0"]}/>,
		id: 0,
		type: "YESNO",
		has_conditional: false
	};

	const light_1 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["light_q1"]}/>,
		id: 1,
		type: "YESNO",
		has_conditional: false
	};

	const light_2 = {
		question: <FormattedMessage id={trafficQuestionTrans[language]["light_q2"]}/>,
		id: 2,
		type: "YESNO",
		has_conditional: false
	};

	return [light_0,light_1,light_2];
}

const NUM_QUESTIONS_PER_SYMPTOM = {
	'sob': 4, 'chest_discomfort': 3, 'fatigue': 4,
	'weight_change': 3, 'confusion': 4, 'leg_swelling': 5, 'lightheaded': 3
}

const REQUIRED_QS_PER_SYMPTOM = {
	'sob': [0,1,2], 'chest_discomfort': [0,1], 'fatigue': [0,1],
	'weight_change': [0], 'confusion': [0,1,2], 'leg_swelling': [0,1], 'lightheaded': [0,1,2]
}

const MC_QUESTION_SIZE = {
    'sob': 7, 'chest_discomfort': 7, 'fatigue': 9, 'weight_change': 5, 
	'confusion': 8, 'leg_swelling': -1, 'lightheaded': -1 
}

export {
	NUM_QUESTIONS_PER_SYMPTOM, REQUIRED_QS_PER_SYMPTOM, MC_QUESTION_SIZE
};